import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export const RequestHandler = ({ loading, error, children }) => {
  const renderProgress = () => (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '46%',
      }}
    >
      <CircularProgress size={50} />
    </Box>
  );

  if (loading) {
    return renderProgress();
  }

  if (error) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '46%',
        }}
      >
        <Typography color='red'>{error?.message}</Typography>
      </Box>
    );
  }

  return <>{children}</>;
};

export default RequestHandler;

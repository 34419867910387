import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  dropdown: {
    fontSize: 12,
    letterSpacing: 0,
    color: '#5C5C5C',
    fontStyle: 'normal',
    fontWeight: 400,
    borderColor: '#C5D7E0',
    borderRadius: 8,
    maxWidth: '435px',
    width: '100%',
    height: '32px',
    marginTop: '60px',
    padding: '0px 16px 0px 8px',
    '&:focus': {
      borderColor: '#0B486B',
    },
  },
}));

export default useStyles;

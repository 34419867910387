import React from 'react';

import { client } from '../../graphql/client/client';
import { useCurrentUserLazyQuery } from '../../hooks';
import { actionTypes, authReducer } from './auth-context-reducer';

export const AuthProvider = ({ children }) => {
  const [currentUserLazyQuery] = useCurrentUserLazyQuery({
    onCompleted: (response) => {
      if (response.currentUser) {
        dispatch({ type: actionTypes.SUCCESS_AUTH });
      }
    },
  });

  const [state, dispatch] = React.useReducer(authReducer, {
    isAuth: false,
  });

  React.useEffect(() => {
    if (localStorage.getItem('token_admin')) {
      currentUserLazyQuery();
    }
  }, [currentUserLazyQuery]);

  const handleSuccessAuth = (token, user) => {
    if (token) {
      localStorage.setItem('token_admin', token);
      localStorage.setItem('fullName', user.fullName);
    }
    dispatch({ type: actionTypes.SUCCESS_AUTH });
  };

  const handleLogout = async () => {
    sessionStorage.removeItem('token_admin');
    localStorage.removeItem('token_admin');
    localStorage.removeItem('authKey');
    sessionStorage.removeItem('authKey');
    await client.clearStore();
    dispatch({ type: actionTypes.LOGOUT });
  };

  const value = {
    isAuth: state.isAuth,
    onSuccessAuth: handleSuccessAuth,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthContext = React.createContext({
  isAuth: false,
  onSuccessAuth: () => {},
  onLogout: () => {},
});

export function useAuthContext() {
  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

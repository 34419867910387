import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SaveIcon from '@mui/icons-material/Save';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import ruLocale from 'date-fns/locale/ru';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DashButton from '../../../components/button';
import ProtectedRoute from '../../../components/protected-route';
import RequestHandler from '../../../components/request-handler/request-handler';
import {
  useAlert,
  useEditUserAsAdmin,
  useGetAllUniversity,
  useGetUserById,
} from '../../../hooks';
import { editUserSchema } from '../../../validation/schemas/edit-user.schema';
import { BanModal } from './ban-modal';
import { ChangePasswordModal } from './change-password-modal';
import PurchaseList from './purchase-list';
import useStyles from './style';

const UserInfo = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const { data: user, ...getUserByIdProps } = useGetUserById({
    variables: {
      id: id,
    },
  });

  const [editUserAsAdminMutation] = useEditUserAsAdmin();

  const { data: universities } = useGetAllUniversity();

  const localeMap = {
    ru: ruLocale,
  };

  const maskMap = {
    ru: '__.__.____',
  };

  const institutionValue = user?.getUserById.university
    ? {
        name: user.getUserById.university?.name,
        icon: user.getUserById.university?.icon,
        id: user.getUserById.university.id,
      }
    : undefined;

  const initialValues = {
    name: user?.getUserById?.fullName || '',
    email: user?.getUserById?.email || '',
    institution: institutionValue || null,
    phone: user?.getUserById?.phone || '',
    dateOfBirth:
      user?.getUserById?.dateOfBirth === '0'
        ? null
        : user?.getUserById?.dateOfBirth || null,
  };

  const handleFormSubmit = (values) => {
    editUserAsAdminMutation({
      variables: {
        input: {
          userId: parseInt(id, 10),
          fullName: values.name,
          dateOfBirth: values.dateOfBirth
            ? new Date(+values.dateOfBirth)
            : null,
          email: values.email,
          phone: values.phone.toString(),
          universityId:
            values.institution !== null
              ? parseInt(values.institution.id, 10)
              : undefined,
        },
      },
      onCompleted: () => {
        showAlert({
          message: 'Пользователь успешно изменён.',
          variant: 'success',
        }),
          navigate(-1);
      },
      onError: (response) => {
        showAlert({
          message:
            response.message === 'Validation error'
              ? 'Пользователь с такой почтой уже существует!'
              : 'Ошибка при изменении пользователя!',
          variant: 'error',
        });
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          margin: '32px  0px 32px 0px',
        }}
      >
        <RequestHandler {...getUserByIdProps}>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' justifyContent='space-between' width='100%'>
              <Stack direction='row' spacing='16px' alignItems='center'>
                <ChevronLeftIcon onClick={() => navigate(-1)} />
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontFamily: 'Roboto',
                    fontWeight: '400',
                  }}
                >
                  Добавить пользователя
                </Typography>
              </Stack>
              <Stack direction='row' spacing='24px'>
                <ChangePasswordModal userId={id} />
                <BanModal userId={id} />
              </Stack>
            </Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={editUserSchema}
            >
              <Form>
                <Box mt='38px' maxWidth='893px'>
                  <Typography>Имя Фамилия</Typography>
                  <Field name='name'>
                    {({ field, meta }) => (
                      <TextField
                        error={meta.error !== undefined}
                        helperText={meta.error}
                        variant='standard'
                        fullWidth
                        {...field}
                      />
                    )}
                  </Field>
                </Box>
                <Stack
                  spacing='24px'
                  direction='row'
                  alignItems='center'
                  mt='24px'
                >
                  <Box maxWidth='435px' width='100%'>
                    <Typography>Дата рождения</Typography>
                    <Field name='dateOfBirth'>
                      {({ field, form }) => {
                        return (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={localeMap['ru']}
                          >
                            <DesktopDatePicker
                              value={
                                field.value ? new Date(+field.value) : null
                              }
                              mask={maskMap['ru']}
                              name={field.name}
                              placeholder='Добавить дату'
                              onChange={(newValue) => {
                                form.setFieldValue(field.name, newValue);
                              }}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'Добавить дату',
                                    }}
                                    variant='standard'
                                    sx={{ maxWidth: '435px', width: '100%' }}
                                  />
                                </>
                              )}
                            />
                          </LocalizationProvider>
                        );
                      }}
                    </Field>
                  </Box>
                  <Box maxWidth='435px' width='100%'>
                    <Typography>Email</Typography>
                    <Field name='email'>
                      {({ field, meta }) => (
                        <TextField
                          error={meta.error !== undefined}
                          helperText={meta.error}
                          type='email'
                          variant='standard'
                          fullWidth
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                </Stack>
                <Box
                  display='flex'
                  dlexDirection='row'
                  alignItems='space-between'
                >
                  <Stack
                    spacing='24px'
                    direction='row'
                    alignItems='center'
                    mt='24px'
                    width='100%'
                  >
                    <Box maxWidth='435px' width='100%'>
                      <Typography>Учебное заведение</Typography>
                      <Field name='institution'>
                        {({ field, form }) => {
                          return (
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              options={
                                (universities &&
                                  universities.getAllUniversity) ||
                                []
                              }
                              getOptionLabel={(option) => {
                                return option.name;
                              }}
                              className={classes.autocomplete}
                              renderOption={(props, option) => {
                                return (
                                  <Box
                                    {...props}
                                    key={option.name}
                                    className={classes.autocompeteOptions}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      paddingX: '10px',
                                    }}
                                  >
                                    {option.name}
                                    <img
                                      src={`${process.env.REACT_APP_URL}/${option.icon}`}
                                      alt='Emblem'
                                    />
                                  </Box>
                                );
                              }}
                              {...field}
                              onChange={(e, value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    variant='standard'
                                    InputProps={{
                                      ...params.InputProps,
                                      ...(field.value && {
                                        endAdornment: (
                                          <img
                                            src={`${process.env.REACT_APP_URL}/${field.value.icon}`}
                                            alt='Emblem'
                                          />
                                        ),
                                      }),
                                    }}
                                    className={classes.autocompleteWrapper}
                                  />
                                </>
                              )}
                            />
                          );
                        }}
                      </Field>
                    </Box>
                    <Box maxWidth='435px' width='100%'>
                      <Typography>Телефон</Typography>
                      <Field name='phone'>
                        {({ field, meta }) => (
                          <TextField
                            error={meta.error !== undefined}
                            helperText={meta.error}
                            variant='standard'
                            fullWidth
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*',
                            }}
                            type='number'
                            {...field}
                          />
                        )}
                      </Field>
                    </Box>
                  </Stack>
                  <DashButton
                    type='submit'
                    buttonText={'Сохранить'}
                    variant='contained'
                    startIcon={<SaveIcon />}
                    wrapperProps={{
                      maxWidth: '128px',
                      alignSelf: 'flex-end',
                    }}
                  />
                </Box>
              </Form>
            </Formik>
          </Box>
        </RequestHandler>
        <PurchaseList userId={id} />
      </Box>
    </Box>
  );
};

export default ProtectedRoute(UserInfo);

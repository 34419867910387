import { IconButton, MenuItem, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import CloseIcon from '../../assets/icons/CloseIcon';
import DashButton from '../../components/button';
import { GET_ALL_USERS } from '../../graphql/queries';
import { useAlert, useDeleteUser } from '../../hooks';
import useStyles from './style';

export function DeletionModal({ userData, onCloseMenu }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { showAlert } = useAlert();

  const [deleteUserMutation] = useDeleteUser();

  const handleDeleteButtonClick = () => {
    deleteUserMutation({
      variables: {
        id: parseInt(userData.id, 10),
      },
      refetchQueries: [GET_ALL_USERS],
      onError: () =>
        showAlert({ message: 'Ошибка при удалени!', variant: 'error' }),
      onCompleted: () =>
        showAlert({ message: 'Пользователь удален.', variant: 'info' }),
    });
  };

  return (
    <div>
      <MenuItem
        onClick={() => {
          handleOpen();
        }}
      >
        <Typography color='#5C5C5C'>Удалить</Typography>
      </MenuItem>
      <Modal
        keepMounted
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.deleteCollectionModalContainer}>
            <Box
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography
                id='transition-modal-title'
                variant='h6'
                component='h2'
                sx={{ fontSize: '24px', color: '#5C5C5C' }}
              >
                Удаление пользователя
              </Typography>
              <IconButton
                onClick={() => {
                  onCloseMenu(), handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                maxHeight: '139px',
                height: '100%',
                mt: '20px',
              }}
            >
              <Typography
                id='transition-modal-description'
                sx={{ fontSize: '16px', color: '#5C5C5C' }}
              >
                Вы действительно хотите удалить с сайта пользователя?
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '16px',
                  pb: '20px',
                }}
              >
                <Stack direction='column' spacing='8px'>
                  <Typography sx={{ fontSize: '16px' }}>Email:</Typography>
                  <Typography sx={{ fontSize: '16px' }}>Имя:</Typography>
                  <Typography sx={{ fontSize: '16px' }}>Номер:</Typography>
                </Stack>
                <Stack direction='column' spacing='8px' ml='16px'>
                  <Typography>{userData.email}</Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: '#5C5C5C',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {userData.fullName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      letterSpacing: '0.03em',
                      textDecoration: 'underline',
                      color: '#0B486B',
                      lineHeight: '146%',
                    }}
                  >
                    {userData.phone}
                  </Typography>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                mt: '32px',
              }}
            >
              <DashButton
                buttonText={'Отмена'}
                variant={'outlined'}
                onClick={() => {
                  onCloseMenu(), handleClose();
                }}
                wrapperProps={{ maxWidth: '139px' }}
              />
              <DashButton
                buttonText={'Удалить'}
                variant={'string'}
                onClick={() => {
                  handleDeleteButtonClick(), onCloseMenu(), handleClose();
                }}
                wrapperProps={{ maxWidth: '113px' }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

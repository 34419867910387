import { gql } from '@apollo/client';

export const getAllUniversityGql = gql`
  query getAllUniversity {
    getAllUniversity {
      id
      name
      icon
    }
  }
`;

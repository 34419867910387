import { useLazyQuery, useQuery } from '@apollo/client';

import * as QUERIES from '../graphql/queries';

export const useGetSelectingsListQuery = (options) => {
  return useQuery(QUERIES.GET_SELECTING_LIST, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetTextBooksQuery = (options) => {
  return useQuery(QUERIES.GET_TEXTBOOKS, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetSubjectsQuery = (options) => {
  return useQuery(QUERIES.GET_SUBJECTS, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useSignInQueryQuery = (options) => {
  return useQuery(QUERIES.SIGN_IN, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useCurrentUserQuery = (options) => {
  return useQuery(QUERIES.CURRENT_USER, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useCurrentUserLazyQuery = (options) => {
  return useLazyQuery(QUERIES.CURRENT_USER, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetSelectionQuery = (options) => {
  return useQuery(QUERIES.GET_SELECTION, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetSelectionByIdQuery = (options) => {
  return useQuery(QUERIES.GET_SELECTION_BY_ID, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetAllUsers = (options) => {
  return useQuery(QUERIES.GET_ALL_USERS, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetAllUniversity = (options) => {
  return useQuery(QUERIES.getAllUniversityGql, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetNews = (options) => {
  return useQuery(QUERIES.GET_NEWS, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetAllStaticPages = (options) => {
  return useQuery(QUERIES.GET_ALL_STATIC_PAGES, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetNewsById = (options) => {
  return useQuery(QUERIES.GET_NEWS_BY_ID, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetUserById = (options) => {
  return useQuery(QUERIES.GET_USER_BY_ID, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetStaticPage = (options) => {
  return useQuery(QUERIES.GET_STATIC_PAGE, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetUserBooksQuery = (options) => {
  return useQuery(QUERIES.GET_USER_BOOKS, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

import { gql } from '@apollo/client';

export const ADD_SLIDE = gql`
  mutation ($selectionId: ID!, $slide: SliderImageInput!) {
    addSlide(selectionId: $selectionId, slide: $slide) {
      id
      alt
      url
    }
  }
`;

import React from 'react';

function ExitIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='12'
      fill='none'
      viewBox='0 0 14 12'
    >
      <path
        fill='#0B486B'
        d='M10.333 2.667l-.94.94 1.72 1.726h-6.78v1.334h6.78l-1.72 1.72.94.946L13.666 6l-3.333-3.333zM1.666 1.333H7V0H1.666C.933 0 .333.6.333 1.333v9.334C.333 11.4.933 12 1.666 12H7v-1.333H1.666V1.333z'
      ></path>
    </svg>
  );
}

export default ExitIcon;

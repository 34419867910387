import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DashButton from '../../../components/button';
import RequestHandler from '../../../components/request-handler/request-handler';
import DashSwitch from '../../../components/switch';
import TextField from '../../../components/text-field';
import {
  useAlert,
  useCreatNewOrEditOldNews,
  useFileUpload,
  useGetNewsById,
} from '../../../hooks';
import { AddSelectedFiles } from '../../catalog/collections/[id]/slider-modal/modal-add-selected-files';
import MyEditor from '../text-editor';
import useStyles from './static-page-edit-styles';

const EditNews = () => {
  const classes = useStyles();

  const [publishStatus, setPublishStatus] = React.useState(false);
  const [createNewOrEditOldNewsMutation] = useCreatNewOrEditOldNews();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { selectedFiles, onDeleteFile, getRootProps, getInputProps } =
    useFileUpload();
  const { id } = useParams();
  const { data: news, ...getNewsByIdProps } = useGetNewsById({
    variables: {
      id: parseInt(id, 10),
    },
    onCompleted: (response) => {
      setPublishStatus(response.getNewsById.visible);
    },
  });

  const initialValues = {
    name: news?.getNewsById.title || '',
    metaTags: news?.getNewsById.metaKeywords || '',
    metaDescription: news?.getNewsById.metaDescription || '',
    editorState: news?.getNewsById?.content || '',
  };

  const handleFormSubmit = (values) => {
    if (values.editorState === '') {
      showAlert({
        message: 'Заполните обязательные поля!',
        variant: 'warning',
      });
    } else if (values.editorState !== '') {
      createNewOrEditOldNewsMutation({
        variables: {
          input: {
            id: parseInt(id, 10),
            title: values.name,
            metaKeywords: values.metaTags,
            metaDescription: values.metaDescription,
            content: values.editorState,
            imageAlt: 'preview',
            visible: publishStatus,
            file: selectedFiles[0],
          },
        },
        onCompleted: () => {
          showAlert({
            message: 'Данные успешно сохранены',
            variant: 'success',
          });
          navigate(-1);
        },
        onError: () => {
          showAlert({ message: 'Данные не сохранены', variant: 'error' });
        },
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          margin: '32px  0px 32px 0px',
        }}
      >
        <RequestHandler {...getNewsByIdProps}>
          <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
            {({ setFieldValue }) => (
              <Form>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <Stack direction='row' spacing='16px' alignItems='center'>
                    <ChevronLeftIcon onClick={() => navigate(-1)} />
                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontFamily: 'Roboto',
                        fontWeight: '400',
                      }}
                    >
                      Редактирование новости
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing='40px' alignItems='center'>
                    <Box display='flex' flexDirection='row'>
                      <Typography width='155px' mr='8px' textAlign='right'>
                        {publishStatus ? 'Опубликовано' : 'Снято с публикации'}
                      </Typography>
                      <DashSwitch
                        onChange={setPublishStatus}
                        checked={publishStatus}
                      />
                    </Box>
                    <DashButton
                      type='submit'
                      variant='contained'
                      buttonText={'Сохранить'}
                      startIcon={<SaveIcon />}
                      wrapperProps={{ maxWidth: '128px' }}
                    />
                  </Stack>
                </Box>
                <Box display='flex' alignItems='center' flexDirection='column'>
                  <Box mt='44px' maxWidth='893px' width='100%'>
                    <Field name='name'>
                      {({ field, meta }) => (
                        <TextField
                          error={meta.error !== undefined}
                          placeholder='Название'
                          helperText={meta.error}
                          variant='standard'
                          fullWidth
                          required
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box mt='24px' maxWidth='893px' width='100%'>
                    <Field name='metaTags'>
                      {({ field, meta }) => (
                        <TextField
                          error={meta.error !== undefined}
                          placeholder='Мета-теги'
                          helperText={meta.error}
                          variant='standard'
                          fullWidth
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box mt='24px' maxWidth='893px' width='100%'>
                    <Field name='metaDescription'>
                      {({ field, meta }) => (
                        <TextField
                          error={meta.error !== undefined}
                          placeholder='Мета-описание'
                          helperText={meta.error}
                          variant='standard'
                          fullWidth
                          required
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                  <div
                    {...getRootProps()}
                    style={{
                      maxWidth: '893px',
                      width: '100%',
                      justifySelf: 'center',
                      marginTop: '32px',
                    }}
                  >
                    <Box>
                      <img
                        src={news?.getNewsById?.image}
                        alt={news?.getNewsById?.imageAlt}
                        className={classes.editNewsImg}
                      />
                    </Box>
                    <input {...getInputProps()} name='file' multiple={false} />
                    <AddSelectedFiles
                      disabled={selectedFiles.length > 0}
                      selectedFiles={selectedFiles}
                      onDeleteFile={onDeleteFile}
                    />
                  </div>
                  <MyEditor
                    setFieldValue={setFieldValue}
                    initialValue={news?.getNewsById?.content || ''}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </RequestHandler>
      </Box>
    </Box>
  );
};

export default EditNews;

import LockResetIcon from '@mui/icons-material/LockReset';
import { IconButton, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';

import CloseIcon from '../../../assets/icons/CloseIcon';
import DashButton from '../../../components/button';
import TextField from '../../../components/text-field';
import { useAlert, useChangePasswordAsAdmin } from '../../../hooks';
import useStyles from './style';

export function ChangePasswordModal({ userId }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { showAlert } = useAlert();

  const [changePasswordAsAdminMutation] = useChangePasswordAsAdmin();

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const handleFormSubmit = (values, { resetForm }) => {
    changePasswordAsAdminMutation({
      variables: {
        userId: parseInt(userId, 10),
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      },
      onCompleted: () => {
        showAlert({
          message: 'Пароль успешно сохранен.',
          variant: 'success',
        });
        resetForm();
      },
      onError: () => {
        showAlert({ message: 'Пароль не сохранен!', variant: 'error' });
        resetForm();
      },
    });
    handleClose();
    resetForm();
  };

  return (
    <div>
      <DashButton
        buttonText={'Изменить пароль'}
        variant='contained'
        startIcon={<LockResetIcon />}
        wrapperProps={{ maxWidth: '174px' }}
        onClick={() => {
          handleOpen();
        }}
      />
      <Modal
        keepMounted
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.changePasswordContainer}>
            <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
              <Form>
                <Box
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                    sx={{ fontSize: '24px', color: '#5C5C5C' }}
                  >
                    Изменение пароля
                  </Typography>
                  <IconButton type='reset' onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Stack spacing='20px' mt='48px'>
                  <Field name='newPassword'>
                    {({ field, meta }) => {
                      return (
                        <TextField
                          required
                          inputType='password'
                          placeholder='Новый пароль'
                          touched={meta.touched}
                          error={meta.error}
                          {...field}
                          onChangeField={() => {}}
                          wrapperProps={{ maxWidth: '390px' }}
                        />
                      );
                    }}
                  </Field>
                  <Field name='confirmPassword'>
                    {({ field, meta }) => {
                      return (
                        <TextField
                          required
                          inputType='password'
                          placeholder='Подтвердите пароль'
                          touched={meta.touched}
                          error={meta.error}
                          {...field}
                          onChangeField={() => {}}
                          wrapperProps={{ maxWidth: '390px' }}
                        />
                      );
                    }}
                  </Field>
                </Stack>
                <Box display='flex' justifyContent='center' mt='48px'>
                  <DashButton
                    type='submit'
                    buttonText={'Изменить пароль'}
                    variant='contained'
                    wrapperProps={{ maxWidth: '184px' }}
                  />
                </Box>
              </Form>
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

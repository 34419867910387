import { Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const DashCheckbox = ({
  id,
  value,
  idList,
  isLimited,
  setIdList,
  wrapperProps,
  ...checkBoxProps
}) => {
  const handleChange = (event) => {
    if (event.target.checked === false) {
      idList.splice(
        idList.indexOf(idList.find((id) => id === event.target.id)),
        1,
      );
      setIdList([...idList]);
    }
    if (event.target.checked == true) {
      setIdList([...idList, event.target.id]);
    }
  };

  return (
    <Box sx={{ ...wrapperProps }}>
      <Checkbox
        id={id}
        checked={value}
        disabled={isLimited ? idList.length > 4 && value !== true : false}
        onChange={handleChange}
        {...checkBoxProps}
      />
    </Box>
  );
};

export default DashCheckbox;

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import DashButton from '../../../components/button';
import EntitiesCountSwitcher from '../../../components/entities-count-switcher';
import DashPagination from '../../../components/pagination';
import ProtectedRoute from '../../../components/protected-route';
import { RequestHandler } from '../../../components/request-handler/request-handler';
import TextField from '../../../components/text-field';
import { IS_MD_WIDTH } from '../../../constants';
import { useGetAllUsers } from '../../../hooks';
import UsersIconMenu from '../users-icon-menu';

const AdminsAuthorsList = () => {
  const [sortTypeMain, setSortType] = useState('ASC');

  const isMdWidth = useMediaQuery(IS_MD_WIDTH);

  const [filter, setFilter] = useState({
    searchString: '',
    firstFilter: undefined,
    secondFilter: undefined,
  });

  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    page: 1,
  });

  const { data: users, ...allUsersQueryProps } = useGetAllUsers({
    variables: {
      searchString: filter.searchString,
      limit: pagination.limit,
      offset: pagination.offset,
      UserSortField: 'REGISTRATION_DATE',
      SortType: sortTypeMain,
      roles: ['ADMIN', 'AUTHOR'],
    },
  });

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          margin: '32px  0px 32px 0px',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontFamily: 'Roboto',
            fontWeight: '400',
          }}
        >
          Администраторы и авторы
        </Typography>
        <Link to='/users/create-user'>
          <DashButton
            variant={'contained'}
            buttonText={'Добавить пользователя'}
            startIcon={<PersonAddAltIcon />}
            wrapperProps={{ maxWidth: '231px' }}
          />
        </Link>
      </Box>
      <Box>
        <TextField
          placeholder={'Поиск'}
          onChangeField={setFilter}
          isSearchBar
        />
      </Box>
      <RequestHandler {...allUsersQueryProps}>
        <TableContainer sx={{ mt: '30px' }}>
          <Table padding='none'>
            <TableHead>
              <TableRow>
                <TableCell
                  align='left'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  Пользователь
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        margin: 'auto 0',
                      }}
                    >
                      Дата регистрации
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <ExpandLessIcon
                        onClick={() => {
                          setSortType('DESC');
                        }}
                        sx={{
                          display: 'inline-block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                      />
                      <ExpandMoreIcon
                        onClick={() => {
                          setSortType('ASC');
                        }}
                        sx={{
                          display: 'block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align='right'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      mr: '20px',
                    }}
                  >
                    Роль
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.getAllUsers?.users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                    }}
                  >
                    <Link to={`user-info/${user.id}`}>
                      <Typography
                        sx={{
                          marginTop: '26px',
                          fontFamily: 'Roboto',
                          fontSize: '15px',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          color: '#0B486B',
                        }}
                      >
                        {user.fullName}
                      </Typography>
                    </Link>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        color: '#5C5C5C',
                        marginTop: '8px',
                      }}
                    >
                      {user.email}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        color: '#5C5C5C',
                        marginTop: '4px',
                        marginBottom: '16px',
                      }}
                    >
                      {user.phone}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={'center'}
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        color: '#5C5C5C',
                      }}
                    >
                      {!parseInt(user?.registrationDate, 10)
                        ? undefined
                        : format(
                            parseInt(user?.registrationDate, 10),
                            'dd/MM/yyyy',
                          )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'inline-block',
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        color: '#5C5C5C',
                      }}
                    >
                      {user.role.id === '1'
                        ? 'Администратор'
                        : user.role.id === '2'
                        ? 'Автор'
                        : ''}
                    </Typography>
                    <UsersIconMenu userData={user} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!allUsersQueryProps.loading ? (
          <Box sx={{ display: 'flex', width: '100%', marginTop: '48px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '55%',
              }}
            >
              <DashPagination
                pagination={pagination}
                setPagination={setPagination}
                pageItemsCount={users?.getAllUsers.count}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'end',
                width: '40%',
                marginBottom: '40px',
              }}
            >
              <EntitiesCountSwitcher
                placeholder={'Пользователей на странице:'}
                currentCount={pagination.limit}
                pagination={pagination}
                setPagination={setPagination}
                wrapperProps={{
                  maxWidth: isMdWidth ? '191px' : 'none',
                }}
              />
            </Box>
          </Box>
        ) : null}
      </RequestHandler>
    </Box>
  );
};

export default ProtectedRoute(AdminsAuthorsList);

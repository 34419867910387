import { IconButton, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import { Box } from '@mui/system';
import React from 'react';

import ExitIcon from '../../assets/icons/ExitIcon';
import HeaderUserIcon from '../../assets/icons/HeaderUserIcon';

export default function UserMenu({ onLogout }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} sx={{ paddingX: '0px' }}>
        <HeaderUserIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box sx={{ margin: '8px 16px', maxWidth: '149px', maxHeight: '112px' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.4)',
            }}
          >
            {localStorage.getItem('fullName')}
          </Typography>
          <Box
            sx={{
              height: '1px',
              width: '149px',
              backgroundColor: '#C5D7E0',
              my: '16px',
            }}
          />
          <Box
            onClick={handleLogout}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <ExitIcon />
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                color: '#0B486B',
                ml: '16px',
              }}
            >
              Выход
            </Typography>
          </Box>
        </Box>
      </Menu>
    </div>
  );
}

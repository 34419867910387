import { Pagination } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React from 'react';

import { IS_MD_WIDTH, IS_SM_WIDTH } from '../../constants';

const DashPagination = ({ pagination, setPagination, pageItemsCount }) => {
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const isSmWidth = useMediaQuery(IS_SM_WIDTH);

  const handleChange = (event, value) => {
    setPagination({
      ...pagination,
      offset: (value - 1) * pagination.limit,
      page: value,
    });
  };

  return (
    <Box mb={isMdWidth ? 0 : 3}>
      {Math.ceil(pageItemsCount / pagination.limit) !== 1 ? (
        <Pagination
          count={Math.ceil(pageItemsCount / pagination.limit)}
          shape='rounded'
          color='primary'
          boundaryCount={1}
          siblingCount={0}
          showFirstButton={
            isSmWidth && Math.ceil(pageItemsCount / pagination.limit > 3)
              ? true
              : false
          }
          showLastButton={
            isSmWidth && Math.ceil(pageItemsCount / pagination.limit > 3)
              ? true
              : false
          }
          page={pagination.page}
          onChange={handleChange}
        />
      ) : null}
    </Box>
  );
};

export default DashPagination;

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router';

const DoesNotExist = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: -12,
        }}
      >
        <Typography
          sx={{ fontSize: '180px', color: '#5C5C5C', textAlign: 'center' }}
        >
          404
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            color: '#5C5C5C',
            textAlign: 'center',
            mb: 1,
          }}
        >
          Мы не можем найти эту страницу!
        </Typography>
        <Typography
          sx={{ fontSize: '20px', color: '#5C5C5C', textAlign: 'center' }}
        >
          Мы сожалеем, но страница на которую вы пытались перейти не существует.
          Пожалуйста, вернитесь на предыдущую страницу или воспользуйтесь меню
          сайта.
        </Typography>
        <Button
          variant='contained'
          sx={{ maxWidth: '50%', mt: 2, color: 'info' }}
          onClick={goBack}
        >
          На предыдущую страницу
        </Button>
      </Box>
    </Box>
  );
};

export default DoesNotExist;

import { gql } from '@apollo/client';

export const EDIT_ADMIN_OR_AUTHOR = gql`
  mutation editAdminOrAuthor(
    $email: String!
    $phone: String
    $fullName: String!
    $password: String
    $role: String!
  ) {
    editAdminOrAuthor(
      email: $email
      phone: $phone
      fullName: $fullName
      password: $password
      role: $role
    ) {
      status
    }
  }
`;

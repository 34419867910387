import { gql } from '@apollo/client';

export const GET_NEWS_BY_ID = gql`
  query getNewsById($id: Int!) {
    getNewsById(id: $id) {
      id
      title
      description
      content
      image
      imageAlt
      visible
      viewsCount
      createdAt
      updatedAt
      publishedAt
      metaDescription
      metaKeywords
    }
  }
`;

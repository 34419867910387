import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { GET_NEWS } from '../../../graphql/queries';
import { useHideNews, useShowNews } from '../../../hooks';
import { DeletionModal } from './deletion-modal';

const NewsPagesIconMenu = ({ newsData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleOpenMenu = (e) => setAnchorEl(e.currentTarget);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [showNewsMutation] = useShowNews();
  const [hideNewsMutation] = useHideNews();

  const handleShowNews = () => {
    showNewsMutation({
      variables: {
        id: Number(newsData.id),
      },
      refetchQueries: [GET_NEWS],
    });
  };

  const handleHideNews = () => {
    hideNewsMutation({
      variables: {
        id: Number(newsData.id),
      },
      refetchQueries: [GET_NEWS],
    });
  };

  return (
    <Box
      sx={{
        display: 'inline-block',
      }}
    >
      <Box>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls='long-menu'
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleOpenMenu}
        >
          <MoreVertIcon sx={{ width: '20px' }} />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
        >
          <MenuItem onClick={handleCloseMenu}>
            <Link to={`edit-news/${newsData?.id}`}>
              <Typography color='#5C5C5C'>Редактировать</Typography>
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleShowNews(), handleCloseMenu();
            }}
          >
            <Typography color='#5C5C5C'>Показать</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleHideNews(), handleCloseMenu();
            }}
          >
            <Typography color='#5C5C5C'>Скрыть</Typography>
          </MenuItem>
          <DeletionModal newsData={newsData} onCloseMenu={handleCloseMenu} />
        </Menu>
      </Box>
    </Box>
  );
};

export default NewsPagesIconMenu;

import { Box, Typography } from '@mui/material';
import React from 'react';

import Image2Icon from '../../../../../assets/icons/Image2Icon';
import Trash2Icon from '../../../../../assets/icons/Trash2Icon';

export const AddSelectedFiles = ({ selectedFiles, onDeleteFile }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#EEF9FD',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        display: 'flex',
        height: '200px',
        overflow: 'hidden',
      }}
    >
      {selectedFiles.length === 0 ? (
        <>
          <Box
            sx={{
              border: '1px dashed #0B486B',
              borderRadius: '12px',
              maxWidth: '704px',
              cursor: 'pointer',
              height: '184px',
              width: '100%',
              mx: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box mt='16px'>
                <Image2Icon />
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  color: '#0B486B',
                  mt: '24px',
                  textAlign: 'center',
                }}
              >
                Перетащите изображение или нажмите сюда
              </Typography>
              <Typography
                color='rgba(0, 0, 0, 0.4)'
                fontFamily='Roboto'
                maxWidth='350px'
                textAlign='center'
                mt='36px'
              >
                Поддерживаемые форматы: .jpg, .png, .gif Размер файла - до 10 Мб
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            width='72px'
            height='72px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            backgroundColor='white'
            borderRadius='12px'
            position='absolute'
            sx={{
              cursor: 'pointer',
              zIndex: 10,
              '&:hover': {
                backgroundColor: '#EEF9FD',
              },
            }}
            onClick={() => onDeleteFile(selectedFiles[0])}
          >
            <Trash2Icon width='40' height='40' color='red' />
          </Box>
          <Box>
            <img src={selectedFiles[0]?.preview} style={{ width: '100%' }} />
          </Box>
        </>
      )}
    </Box>
  );
};

import React from 'react';

function HeaderUserIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <path
        fill='#fff'
        d='M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20z'
      ></path>
      <path
        fill='#4D6E7D'
        d='M28.121 25.897a8.767 8.767 0 00-1.889-2.8 8.804 8.804 0 00-2.8-1.89c-.01-.005-.02-.007-.029-.012A5.811 5.811 0 0020 10.672a5.811 5.811 0 00-3.403 10.526l-.028.011a8.727 8.727 0 00-2.801 1.89 8.802 8.802 0 00-2.58 6.037.185.185 0 00.053.136.187.187 0 00.134.056h1.406a.187.187 0 00.188-.183 6.988 6.988 0 012.058-4.788A6.985 6.985 0 0120 22.297c1.88 0 3.645.731 4.974 2.06a6.988 6.988 0 012.057 4.788.186.186 0 00.188.183h1.406a.188.188 0 00.188-.192 8.736 8.736 0 00-.692-3.24zm-8.12-5.381a4.005 4.005 0 01-2.85-1.182 4.005 4.005 0 01-1.182-2.85c0-1.075.42-2.088 1.181-2.85a4.005 4.005 0 012.85-1.18c1.076 0 2.088.419 2.85 1.18a4.005 4.005 0 011.181 2.85c0 1.076-.42 2.089-1.18 2.85A4.005 4.005 0 0120 20.516z'
      ></path>
    </svg>
  );
}

export default HeaderUserIcon;

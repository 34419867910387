import { useApolloClient } from '@apollo/client';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import DashButton from '../../components/button';
import ProtectedRoute from '../../components/protected-route';
import TextField from '../../components/text-field';
import { IS_SM_WIDTH } from '../../constants';
import { SIGN_IN } from '../../graphql/queries';
import { useAuth } from '../../hooks';
import { loginSchema } from '../../validation/schemas/login.schema';
import useStyles from './styles';

const Home = () => {
  const client = useApolloClient();
  const { onSuccessAuth } = useAuth();
  const classes = useStyles();
  const isSmWidth = useMediaQuery(IS_SM_WIDTH);

  return (
    <Box className={classes.homePage}>
      <div className={classes.authBox}>
        <Typography sx={{ fontSize: '24px' }} className={classes.welcomeText}>
          Добро пожаловать!
        </Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values, actions) => {
            client
              .query({
                query: SIGN_IN,
                variables: {
                  email: values.email,
                  password: values.password,
                  role: 'ADMIN',
                },
              })
              .then((response) => {
                onSuccessAuth(
                  response.data.signIn.token,
                  response.data.signIn.user,
                );
              })
              .catch((e) => {
                actions.setErrors({
                  email: e.message,
                  password: e.message,
                });
              });
          }}
          validationSchema={loginSchema}
        >
          <Form>
            <div className={classes.formContainer}>
              <Field name='email'>
                {({ field, meta }) => {
                  return (
                    <TextField
                      inputType='email'
                      placeholder='Email адрес'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      onChangeField={() => {}}
                      wrapperProps={{
                        minWidth: isSmWidth ? '386px' : 'unset',
                        pb: '20px',
                      }}
                    />
                  );
                }}
              </Field>
              <Field name='password'>
                {({ field, meta }) => {
                  return (
                    <TextField
                      inputType='password'
                      placeholder='Пароль'
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                      onChangeField={() => {}}
                      wrapperProps={{ minWidth: isSmWidth ? '386px' : 'unset' }}
                    />
                  );
                }}
              </Field>
            </div>
            <div className={classes.alignCenter}>
              <DashButton
                variant='contained'
                buttonText='Вход'
                type='submit'
                wrapperProps={{ maxWidth: '139px' }}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </Box>
  );
};

export default ProtectedRoute(Home);

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import React from 'react';

import RequestHandler from '../../../components/request-handler/request-handler';
import { useGetUserBooksQuery } from '../../../hooks';

const PurchaseList = ({ userId }) => {
  const [sortField, setSortField] = React.useState('DATE');
  const [sortType, setSortType] = React.useState('DESC');

  const { data: books, ...getUserBooksQueryProps } = useGetUserBooksQuery({
    variables: {
      options: {
        userId: userId,
        sort: {
          sortBy: sortField,
          flow: sortType,
        },
      },
    },
  });

  return (
    <>
      <div style={{ marginTop: '40px' }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontFamily: 'Roboto',
            fontWeight: '400',
          }}
        >
          Покупки
        </Typography>
      </div>
      <RequestHandler {...getUserBooksQueryProps}>
        <TableContainer sx={{ mt: '32px' }}>
          <Table padding='none'>
            <TableHead>
              <TableRow>
                <TableCell
                  align='left'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  Название
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        margin: 'auto 0',
                      }}
                    >
                      Дата покупки
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <ExpandLessIcon
                        sx={{
                          display: 'inline-block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSortField('PRICE');
                          setSortType('ASC');
                        }}
                      />
                      <ExpandMoreIcon
                        sx={{
                          display: 'block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSortField('PRICE');
                          setSortType('DESC');
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      align='right'
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        margin: 'auto 0',
                      }}
                    >
                      Цена
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <ExpandLessIcon
                        sx={{
                          display: 'inline-block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSortField('DATE');
                          setSortType('ASC');
                        }}
                      />
                      <ExpandMoreIcon
                        sx={{
                          display: 'block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSortField('DATE');
                          setSortType('DESC');
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {books?.getUserBooks?.textbooks?.map((book, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '96px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_URL}/${book.textbook.cover}`}
                        width='50px'
                        height='80px'
                        alt='picture-book'
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '15px',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          color: '#0B486B',
                          marginLeft: '24px',
                        }}
                      >
                        {book.textbook.title}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        color: '#5C5C5C',
                      }}
                    >
                      {!parseInt(book.purchaseDate, 10)
                        ? undefined
                        : format(parseInt(book.purchaseDate, 10), 'dd/MM/yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={'center'}
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'inline-block',
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        color: '#5C5C5C',
                      }}
                    >
                      {book.purchasePrice}
                    </Typography>
                    <Box
                      sx={{
                        display: 'inline-block',
                      }}
                    ></Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RequestHandler>
    </>
  );
};

export default PurchaseList;

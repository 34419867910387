import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DoesNotExist from '../../pages/404/index';
import BooksCatalog from '../../pages/catalog/books/books-catalog';
import EditCollection from '../../pages/catalog/collections/[id]/edit-collection';
import CollectionsCatalog from '../../pages/catalog/collections/collections-catalog';
import CreateCollection from '../../pages/catalog/collections/create-collection';
import Catalog from '../../pages/catalog/index';
import SubjectsCatalog from '../../pages/catalog/subjects/subjects-catalog';
import Home from '../../pages/home/index';
import NewsStaticsPages from '../../pages/pages';
import EditNews from '../../pages/pages/[id]/edit-news';
import EditStaticPages from '../../pages/pages/[id]/edit-static-pages';
import NewsList from '../../pages/pages/news-list';
import AddNews from '../../pages/pages/news-list/add-news';
import StaticPagesList from '../../pages/pages/static-pages-list';
import AddStaticPage from '../../pages/pages/static-pages-list/add-static-page';
import Users from '../../pages/users';
import UserInfo from '../../pages/users/[id]/user-info';
import AdminsAuthorsList from '../../pages/users/admins-authors-list';
import UsersList from '../../pages/users/users-list';
import CreateUser from '../../pages/users/users-list/create-user';

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='catalog/' element={<Catalog />}>
        <Route path='books' element={<BooksCatalog />} />
        <Route path='subjects' element={<SubjectsCatalog />} />
        <Route path='collections' element={<CollectionsCatalog />} />
        <Route path='collections/create' element={<CreateCollection />} />
        <Route path='collections/edit/:id' element={<EditCollection />} />
        <Route
          path='collections/create-collection'
          element={<CreateCollection />}
        />
      </Route>
      <Route path='users/' element={<Users />}>
        <Route path='create-user' element={<CreateUser />} />
        <Route path='users-list/user-info/:id' element={<UserInfo />} />
        <Route
          path='admins-authors-list/user-info/:id'
          element={<UserInfo />}
        />
        <Route path='users-list' element={<UsersList />} />
        <Route path='admins-authors-list' element={<AdminsAuthorsList />} />
      </Route>
      <Route path='pages/' element={<NewsStaticsPages />}>
        <Route path='static-pages-list' element={<StaticPagesList />} />
        <Route path='news-list' element={<NewsList />} />
        <Route
          path='static-pages-list/edit-page/:id'
          element={<EditStaticPages />}
        />
        <Route path='news-list/edit-news/:id' element={<EditNews />} />
        <Route path='news-list/add-news' element={<AddNews />} />
        <Route path='static-pages-list/add-page' element={<AddStaticPage />} />
      </Route>
      <Route path='*' element={<DoesNotExist />} />
    </Routes>
  );
}

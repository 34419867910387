import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from './components/router';
import { AlertProvider, AuthProvider } from './context';
import { client } from './graphql/client/client';
import Layout from './layout';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <BrowserRouter>
          <AlertProvider>
            <Layout>
              <Router />
            </Layout>
          </AlertProvider>
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;

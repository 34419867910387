import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

import useStyles from './styles';

const NavigationTab = ({ options }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        maxWidth: '100%',
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        mb: '16px',
        background: '#FFF',
      }}
    >
      {options.map((option, idx) => (
        <Box key={idx} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              my: '16px',
            }}
          />
          <Link to={option.path}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                // '& :hover': {
                //   background: 'rgba(196, 196, 196, 0.5)',
                //   textDecorationColor: 'gba(196, 196, 196, 0.5)',
                //   transition: '0.5s ease-out',
                //   cursor: 'pointer',
                // },
              }}
              className={classes.navLine}
            >
              <Box
                sx={{
                  borderRight: '1px solid #CCCCCC',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    color: '#5C5C5C',
                    mx: '23px',
                  }}
                >
                  {option.name}
                </Typography>
              </Box>
            </Box>
          </Link>
          <Box />
        </Box>
      ))}
    </Box>
  );
};

export default NavigationTab;

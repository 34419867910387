import React from 'react';
import MetaTags from 'react-meta-tags';

import Header from '../components/header';
import useStyles from './styles';

const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.pageWrapper}>
      <MetaTags>
        <title>Parta | Админка</title>
        <meta property='og:title' content='Главная' />
      </MetaTags>
      <Header />
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export default Layout;

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

import EntitiesCountSwitcher from '../../../components/entities-count-switcher';
import Filters from '../../../components/filters';
import DashPagination from '../../../components/pagination';
import ProtectedRoute from '../../../components/protected-route';
import { RequestHandler } from '../../../components/request-handler/request-handler';
import { IS_MD_WIDTH } from '../../../constants';
import {
  useGetSelectingsListQuery,
  useGetTextBooksQuery,
} from '../../../hooks';
import useStyles from '../styles';
import IconMenu from './books-icon-menu';

const BooksCatalog = () => {
  const classes = useStyles();
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const [filter, setFilter] = React.useState({
    searchString: '',
    firstFilter: undefined,
    secondFilter: undefined,
    statusFilter: undefined,
  });
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    page: 1,
  });
  const { data: options, ...selectingListQueryProps } =
    useGetSelectingsListQuery();
  const { data: books, ...textBooksQueryProps } = useGetTextBooksQuery({
    variables: {
      options: {
        searchString: filter.searchString,
        selectedSubjectId: filter.firstFilter,
        selectedYear: filter.secondFilter,
        limit: pagination.limit,
        offset: pagination.offset,
        getOnlyWithStatus: filter.statusFilter,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const yearOptionsParser = () => {
    if (selectingListQueryProps.loading) return [];
    let result = [];
    Object.values(options).forEach((option) => {
      Object.values(option)[1]
        .sort()
        .flatMap((item) => {
          result.push(Object.assign({ id: item, name: item }));
        });
    });
    return result;
  };

  const subjectOptionsParser = () => {
    if (selectingListQueryProps.loading) return [];
    let result = [];
    Object.values(options).forEach((option) => {
      option.subjects.map((item) => {
        delete item.alias;
        result.push(item);
      });
    });
    return result;
  };

  const statusLabels = [
    {
      name: 'На модерации',
      id: 'MODERATION',
    },
    {
      name: 'Опубликовано',
      id: 'PUBLISHED',
    },
    {
      name: 'Скрыто',
      id: 'HIDEN',
    },
    {
      name: 'Отклонено',
      id: 'REJECTED',
    },
  ];

  const getStatus = (status) => {
    let labelColor;
    let statusLabel;
    switch (status) {
      case 'SAVED':
        labelColor = '#999999';
        statusLabel = 'Сохранено';
        break;
      case 'MODERATION':
        labelColor = '#999999';
        statusLabel = 'На модерации';
        break;
      case 'PUBLISHED':
        labelColor = '#0BB126';
        statusLabel = 'Опубликовано';
        break;
      case 'HIDEN':
        labelColor = '#999999';
        statusLabel = 'Скрыто';
        break;
      case 'REJECTED':
        labelColor = '#CC0000';
        statusLabel = 'Отклонено';
        break;
      default:
        labelColor = '#660000';
        statusLabel = 'Ошибка';
    }
    return [statusLabel, labelColor];
  };

  return (
    <Box className={classes.catalogContainer}>
      <Typography
        sx={{
          padding: '0px 0 32px 0px',
          fontSize: '24px',
        }}
      >
        Книги
      </Typography>
      <Filters
        firstFilterOptions={subjectOptionsParser()}
        secondFilterOptions={yearOptionsParser()}
        statusFilterOptions={statusLabels}
        isSearchBar
        searchBarPlaceholder={'Название, автор'}
        filterWidth={['360px', '180px']}
        filterPlaceholders={['Предмет', 'Год выпуска', 'Статус']}
        onChangeFilter={setFilter}
        setPagination={setPagination}
        pagination={pagination}
        filter={filter}
        statusFilterVisible
      />
      <RequestHandler {...textBooksQueryProps}>
        <TableContainer sx={{ marginTop: '16px' }}>
          <Table padding='none'>
            <TableHead>
              <TableRow sx={{ fontSize: '12px', color: '#5C5C5C' }}>
                <TableCell
                  align='left'
                  sx={{
                    minWidth: '250px',
                    width: '556px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Название
                </TableCell>
                <TableCell
                  align='left'
                  sx={{
                    minWidth: '120px',
                    width: '236px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Предмет
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    minWidth: '120px',
                    width: '130px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Книг куплено
                </TableCell>
                <TableCell
                  align='right'
                  sx={{
                    minWidth: '90px',
                    width: '166px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Цена
                </TableCell>
                <TableCell
                  align='right'
                  sx={{
                    minWidth: '180px',
                    width: '226px',
                    paddingRight: '40px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Статус
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {books?.getTextbooks?.textbooks?.map((book) => (
                <TableRow key={book.id} sx={{ height: '112px' }}>
                  <TableCell
                    align='left'
                    sx={{
                      minWidth: '250px',
                      width: '556px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_URL}/${book?.cover}`}
                        alt={book.title}
                        className={classes.listItemImage}
                      />
                      <Box
                        onClick={() => {
                          document.cookie = `token_admin=${
                            localStorage.getItem('token_admin') ||
                            sessionStorage.getItem('token_admin')
                          }; domain=parta.demo.malevich.com.ua; path=/; `;
                          window.location = `${process.env.REACT_APP_READER_URL}${book.id}`;
                        }}
                      >
                        <Typography
                          sx={{
                            ml: '24px',
                            fontSize: '15px',
                            textDecoration: 'underline',
                            letterSpacing: '0.03em',
                            color: '#0B486B',
                            cursor: 'pointer',
                          }}
                        >
                          {book.title}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{
                      minWidth: '120px',
                      width: '236px',
                      fontSize: '15px',
                      letterSpacing: '0.03em',
                      color: '#0B486B',
                    }}
                  >
                    {book.subject.name}
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{
                      minWidth: '120px',
                      width: '130px',
                      fontSize: '15px',
                      letterSpacing: '0.03em',
                      color: '#0B486B',
                    }}
                  >
                    {book.purchasedBooksAmount}
                    {/* {book.id} */}
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      minWidth: '90px',
                      width: '166px',
                      fontSize: '15px',
                      letterSpacing: '0.03em',
                      color: '#0B486B',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Typography mr='5px'>{book.price}</Typography>
                      <div className={classes.priceLine} />
                    </Box>
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      minWidth: '180px',
                      width: '226px',
                      letterSpacing: '0.03em',
                      color: getStatus(book.status)[1],
                      fontSize: '15px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {getStatus(book.status)[0]}
                      <IconMenu textBookData={book} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!textBooksQueryProps.loading ? (
          <Box
            className={classes.paginationContainer}
            sx={{
              flexDirection: isMdWidth ? 'row' : 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: isMdWidth ? '33%' : '100%' }} />
            <DashPagination
              pagination={pagination}
              setPagination={setPagination}
              pageItemsCount={books?.getTextbooks.count}
              wrapperProps={{ width: '33%' }}
            />
            <Box
              sx={{
                width: isMdWidth ? '33%' : '100%',
                display: 'flex',
                justifyContent: isMdWidth ? 'flex-end' : 'center',
              }}
            >
              <EntitiesCountSwitcher
                placeholder={'Книг на странице:'}
                currentCount={pagination.limit}
                pagination={pagination}
                setPagination={setPagination}
                wrapperProps={{
                  maxWidth: isMdWidth ? '191px' : 'none',
                }}
              />
            </Box>
          </Box>
        ) : null}
      </RequestHandler>
    </Box>
  );
};

export default ProtectedRoute(BooksCatalog);

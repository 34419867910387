import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 17.6 17.6'
    >
      <path
        fill='#0B486B'
        fillRule='evenodd'
        d='M8 .167a7.333 7.333 0 100 14.667A7.333 7.333 0 008 .167zM11.18 6.26a.666.666 0 10-1.024-.853L7.288 8.846 5.805 7.362a.667.667 0 00-.943.943l2 2a.667.667 0 00.984-.045l3.333-4z'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default Icon;

import { Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React from 'react';

import { IS_MD_WIDTH } from '../../constants';
import { transformDataForSelect } from '../../utils/transform-data-for-select';
import DropdownMenu from '../dropdown-menu';
import TextField from '../text-field';

const Filters = ({
  onChangeFilter,
  setPagination,
  withSingleFilter,
  withoutFilters,
  filterWidth,
  isSearchBar,
  isBasicInput,
  filterPlaceholders,
  firstFilterOptions,
  secondFilterOptions,
  statusFilterOptions,
  searchBarPlaceholder,
  pagination,
  filter,
  statusFilterVisible,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, setField] = React.useState(undefined);
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  return (
    <Box>
      <Stack
        direction={isMdWidth ? 'row' : 'column'}
        spacing={isMdWidth ? '24px' : '10px'}
      >
        <TextField
          inputType='text'
          placeholder={searchBarPlaceholder}
          isSearchBar={isSearchBar}
          isBasicInput={isBasicInput}
          onChangeField={(value) => {
            setPagination
              ? setPagination({ ...pagination, offset: 0, page: 1 })
              : null;
            setField(value),
              onChangeFilter({
                ...filter,
                searchString: value.field,
              });
          }}
        />
        {withoutFilters ? null : (
          <>
            <DropdownMenu
              options={transformDataForSelect(firstFilterOptions)}
              placeholder={filterPlaceholders[0]}
              wrapperProps={{ maxWidth: isMdWidth ? filterWidth[0] : '100%' }}
              onChangeField={(value) => {
                setPagination
                  ? setPagination({ ...pagination, offset: 0, page: 1 })
                  : null;
                setField(value),
                  onChangeFilter({ ...filter, firstFilter: value.field });
              }}
            />
            {!withSingleFilter ? (
              <DropdownMenu
                options={transformDataForSelect(secondFilterOptions)}
                placeholder={filterPlaceholders[1]}
                wrapperProps={{ maxWidth: isMdWidth ? filterWidth[1] : '100%' }}
                onChangeField={(value) => {
                  setPagination
                    ? setPagination({ ...pagination, offset: 0, page: 1 })
                    : null;
                  setField(value),
                    onChangeFilter({ ...filter, secondFilter: value.field });
                }}
              />
            ) : null}
            {statusFilterVisible && (
              <DropdownMenu
                options={transformDataForSelect(statusFilterOptions)}
                placeholder={filterPlaceholders[2]}
                wrapperProps={{ maxWidth: isMdWidth ? filterWidth[1] : '100%' }}
                onChangeField={(value) => {
                  setPagination
                    ? setPagination({ ...pagination, offset: 0, page: 1 })
                    : null;
                  setField(value),
                    onChangeFilter({ ...filter, statusFilter: value.field });
                }}
              />
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Filters;

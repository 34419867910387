import {
  Divider,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router';

import SaveIcon from '../../../../assets/icons/SaveIcon';
import DashButton from '../../../../components/button';
import Filters from '../../../../components/filters';
import ProtectedRoute from '../../../../components/protected-route';
import { RequestHandler } from '../../../../components/request-handler/request-handler';
import { IS_MD_WIDTH } from '../../../../constants';
import {
  useAlert,
  useEditSelectionMutation,
  useGetSelectionByIdQuery,
} from '../../../../hooks';
import useStyles from '../../styles';
import SliderModal from '../[id]/slider-modal';
import CollectionsIconMenu from '../collections-icon-menu';
import CollectionsModal from '../collections-modal';

const COLLECTION_PAGE = [
  { id: 'HOME_PAGE', name: 'Главная' },
  { id: 'SEARCH_RESULTS_PAGE', name: 'Поиск' },
  { id: 'NOVELTIES_PAGE', name: 'Новинки' },
  { id: 'NEWS_MATERIAL_PAGE', name: 'Новости' },
  { id: 'CHECKOUT_PAGE', name: 'Рассчёт' },
];

const EditCollection = () => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const [collection, setCollection] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(undefined);
  const [autoScrolling, setAutoScrolling] = React.useState(false);
  const selectionId = useParams().id;

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [option, setOption] = React.useState({
    searchString: '',
    firstFilter: undefined,
  });

  const { data: initialCollection, ...getSelectionByIdQueryProps } =
    useGetSelectionByIdQuery({
      variables: {
        id: selectionId,
      },
      onCompleted: (response) => {
        let result = [];
        response?.getSelectionById.textbooks.map((book) => {
          result.push(book.id.toString());
        });
        setIdList(result);
        setCollection(response?.getSelectionById.textbooks);
        setSelectedValue(response?.getSelectionById.mainTextbookId || null);
      },
    });

  const [editSelectionMutation] = useEditSelectionMutation({
    variables: {
      data: {
        id: useParams().id,
        title: option.searchString,
        page: option.firstFilter,
        textbooksIds: collection?.map((item) => parseInt(item?.id, 10)),
        mainTextbookId: selectedValue || null,
        sliderAutoScrolling: autoScrolling,
      },
    },
    onCompleted: () =>
      showAlert({ message: 'Подборка сохранена!', variant: 'success' }),
    onError: () =>
      showAlert({ message: 'Ошибка при сохранении!', variant: 'error' }),
  });

  const handleSaveCollection = () => {
    if (option.searchString === '' || option.firstFilter === undefined) {
      return showAlert({ message: 'Заполните все поля!', variant: 'warning' });
    }
    editSelectionMutation();
  };

  const renderBooksPlaceholder = () => {
    let placeholderIds = [1, 2, 3, 4, 5];
    placeholderIds.splice(5 - collection?.length);
    if (initialCollection?.getSelectionById?.style === 'HORIZONTAL')
      return null;
    return (
      <Stack spacing='16px' mt='16px'>
        {placeholderIds.map((placeholder) => (
          <CollectionsModal
            key={placeholder}
            onChangeCollection={setCollection}
            collection={collection}
            idList={idList}
            setIdList={setIdList}
            isLimited
          />
        ))}
      </Stack>
    );
  };

  const renderHorizontalCollection = () => (
    <TableContainer sx={{ marginTop: '48px' }}>
      <Table padding='none'>
        <TableHead>
          <TableRow>
            <TableCell
              align='left'
              sx={{
                minWidth: '250px',
                fontSize: '12px',
                color: '#5C5C5C',
              }}
            >
              Название
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <RequestHandler {...getSelectionByIdQueryProps}>
            {collection?.map((item, idx) => (
              <TableRow key={idx} sx={{ height: '112px' }}>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}/${item?.cover}`}
                      alt={item?.title}
                      className={classes.listItemImage}
                    />
                    <Stack direction='column' spacing='7px' ml='24px'>
                      <Typography
                        sx={{
                          fontSize: '15px',
                          textDecoration: 'underline',
                          letterSpacing: '0.03em',
                          color: '#0B486B',
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#5C5C5C',
                        }}
                      >
                        {item?.authors}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#5C5C5C',
                        }}
                      >
                        {item?.issueYear}
                      </Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell
                  align='right'
                  sx={{
                    minWidth: '50px',
                  }}
                >
                  <CollectionsIconMenu
                    collectionData={Object.assign(item, { idx: idx })}
                    isDeleteOnlyMenu
                    onDelete={setCollection}
                    collectionList={collection}
                    idList={idList}
                    setIdList={setIdList}
                  />
                </TableCell>
              </TableRow>
            ))}
          </RequestHandler>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderNonHorizontalCollection = () => {
    return (
      <>
        {collection?.length === 0 ? null : (
          <>
            <Typography
              sx={{
                minWidth: '250px',
                fontSize: '12px',
                color: '#5C5C5C',
                mt: '51px',
              }}
            >
              Название
            </Typography>
            <Divider sx={{ height: '1px', mt: '2px' }} />
          </>
        )}
        <>
          <RequestHandler {...getSelectionByIdQueryProps}>
            {collection?.map((item, idx) => (
              <Box key={idx}>
                <Box
                  sx={{
                    width: '100%',
                    height: '112px',
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <img
                        src={`${process.env.REACT_APP_URL}/${item?.cover}`}
                        alt={item.title}
                        className={classes.listItemImage}
                      />
                      <Stack direction='column' spacing='7px' ml='24px'>
                        <Typography
                          sx={{
                            fontSize: '15px',
                            textDecoration: 'underline',
                            letterSpacing: '0.03em',
                            color: '#0B486B',
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#5C5C5C',
                          }}
                        >
                          {item.authors}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#5C5C5C',
                          }}
                        >
                          {item.issueYear}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            fontFamily: 'Roboto',
                            fontSize: '12px',
                            color: '#5C5C5C',
                          }}
                        >
                          Промо-позиция
                        </Typography>
                        <Radio
                          checked={selectedValue === item.id}
                          onChange={handleChange}
                          value={item.id}
                          name='radio-buttons'
                        />
                        <CollectionsIconMenu
                          collectionData={Object.assign(item, { idx: idx })}
                          isDeleteOnlyMenu
                          onDelete={setCollection}
                          collectionList={collection}
                          idList={idList}
                          setIdList={setIdList}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ height: '1px' }} />
              </Box>
            ))}
            {renderBooksPlaceholder()}
          </RequestHandler>
        </>
      </>
    );
  };

  return (
    <Box className={classes.catalogContainer}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ padding: '0px 0px 32px 0px', fontSize: '24px' }}>
          Редактирование подборки
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMdWidth ? 'row' : 'column',
            alignItems: isMdWidth ? 'none' : 'center',
            justifyContent: isMdWidth ? 'none' : 'space-evenly',
            mt: isMdWidth ? '0px' : '-12px',
          }}
        >
          {initialCollection?.getSelectionById?.style === 'HORIZONTAL' ? (
            <CollectionsModal
              onChangeCollection={setCollection}
              collection={collection}
              idList={idList}
              setIdList={setIdList}
              isLimited={
                initialCollection?.getSelectionById?.style !== 'HORIZONTAL'
              }
            />
          ) : (
            <SliderModal
              setAutoScrolling={setAutoScrolling}
              slides={initialCollection?.getSelectionById?.slider}
              autoScrolling={autoScrolling}
              selectionId={selectionId}
            />
          )}
          <DashButton
            buttonText={isMdWidth ? 'Сохранить' : ''}
            variant={'contained'}
            color='success'
            startIcon={<SaveIcon />}
            onClick={handleSaveCollection}
            wrapperProps={{
              maxWidth: isMdWidth ? '169px' : '30px',
              ml: isMdWidth ? '16px' : '0px',
              '&.MuiButtonBase-root.MuiButton-root': {
                paddingLeft: !isMdWidth ? '28px' : 'none',
              },
            }}
          />
        </Box>
      </Box>
      <Filters
        firstFilterOptions={COLLECTION_PAGE}
        isBasicInput
        withSingleFilter
        searchBarPlaceholder={'Название'}
        filterWidth={['313.5px']}
        filterPlaceholders={['Страница подборки']}
        onChangeFilter={setOption}
        filter={option}
      />
      {initialCollection?.getSelectionById?.style === 'HORIZONTAL'
        ? renderHorizontalCollection()
        : renderNonHorizontalCollection()}
    </Box>
  );
};

export default ProtectedRoute(EditCollection);

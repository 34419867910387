import React from 'react';

function Group1Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='20'
      fill='none'
      viewBox='0 0 40 20'
    >
      <path fill='#C4C4C4' d='M0 0H12V20H0z'></path>
      <path fill='#C4C4C4' d='M14 11H40V20H14z'></path>
      <path fill='#C4C4C4' d='M14 0H19V9H14z'></path>
      <path fill='#C4C4C4' d='M21 0H26V9H21z'></path>
      <path fill='#C4C4C4' d='M28 0H33V9H28z'></path>
      <path fill='#C4C4C4' d='M35 0H40V9H35z'></path>
    </svg>
  );
}

export default Group1Icon;

import { IconButton, Input, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';

import AddIcon from '../../../assets/icons/AddIcon';
import CloseIcon from '../../../assets/icons/CloseIcon';
import SearchIcon from '../../../assets/icons/SearchIcon';
import DashButton from '../../../components/button';
import DashCheckbox from '../../../components/checkbox';
import { RequestHandler } from '../../../components/request-handler/request-handler';
import { IS_MD_WIDTH } from '../../../constants/constants';
import { useGetTextBooksQuery } from '../../../hooks';
import useStyles from '../styles';

export default function CollectionsModal({
  idList,
  isLimited,
  setIdList,
  onChangeCollection,
}) {
  const classes = useStyles();
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [search, setSearch] = React.useState('');

  const handleChangeInput = (event) => {
    return setSearch({ field: event.target.value });
  };

  const handleCancelClick = () => {
    setSearch('');
  };

  const handleSaveClick = () => {
    let result = [];
    idList.map((id) => {
      result.push(
        books?.getTextbooks?.textbooks.find((textbooks) => textbooks.id == id),
      );
    });
    onChangeCollection([result].flat(1));
    setSearch('');
  };

  const { data: books, ...textBooksQueryProps } = useGetTextBooksQuery({
    variables: {
      options: {
        searchString: search.field,
        limit: 50,
      },
    },
    skip: open !== true,
  });

  return (
    <div>
      {isLimited ? (
        <Box
          sx={{
            backgroundColor: '#EEF9FD',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            maxWidth: '1352px',
            display: 'flex',
            height: '88px',
          }}
        >
          <Box
            onClick={handleOpen}
            sx={{
              border: '1px dashed #0B486B',
              borderRadius: '12px',
              alignItems: 'center',
              maxWidth: '1336px',
              display: 'flex',
              height: '72px',
              width: '100%',
              mx: '8px',
              hover: {
                cursor: 'pointer',
              },
            }}
          >
            <Box sx={{ display: 'flex', ml: '46px' }}>
              <AddIcon fill='#0B486B' />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  color: '#0B486B',
                  ml: '17.5px',
                }}
              >
                Добавить книгу
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <DashButton
          buttonText={isMdWidth ? 'Добавить книги' : ''}
          variant={'contained'}
          startIcon={<AddIcon />}
          onClick={handleOpen}
          wrapperProps={{
            maxWidth: isMdWidth ? '169px' : '30px',
            '&.MuiButtonBase-root.MuiButton-root': {
              paddingLeft: !isMdWidth ? '28px' : 'none',
            },
          }}
        />
      )}
      <Modal
        keepMounted
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 250,
        }}
      >
        <Fade in={open}>
          <Box className={classes.collectionsModalContainer}>
            <Box
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '28px',
                display: 'flex',
                pb: '40px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  color: '#5C5C5C',
                }}
              >
                Добавить книги
              </Typography>
              <IconButton
                onClick={() => {
                  handleClose(), handleCancelClick();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.inputWrapper} variant='standard'>
              <Input
                className={classes.inputBase}
                placeholder='Поиск'
                value={search.field || ''}
                endAdornment={<SearchIcon />}
                onChange={handleChangeInput}
              />
            </Box>
            <Box
              sx={{
                maxHeight: '296px',
                maxWidth: '720px',
                height: '100%',
                width: '100%',
                borderRadius: '8px',
                overflowY: 'auto',
                border: '1px solid #C5D7E0',
                mt: '40px',
              }}
            >
              <RequestHandler {...textBooksQueryProps}>
                {books?.getTextbooks?.textbooks?.map((book) => (
                  <Box
                    key={book.id}
                    sx={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      mx: '16px',
                      py: '16px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        minWidth: '270px',
                      }}
                    >
                      <DashCheckbox
                        id={book.id}
                        idList={idList}
                        setIdList={setIdList}
                        wrapperProps={{ padding: 0, mr: '28px' }}
                        value={idList.includes(book.id)}
                        isLimited={isLimited}
                      />
                      <img
                        src={`${process.env.REACT_APP_URL}/${book?.cover}`}
                        alt={book.title}
                        className={classes.listItemImage}
                      />
                      <Typography
                        sx={{
                          textDecoration: 'underline',
                          fontFamily: 'Roboto',
                          fontSize: '15px',
                          letterSpacing: '0.03em',
                          color: '#0B486B',
                          ml: '28px',
                        }}
                      >
                        {book.title}
                      </Typography>
                    </Box>
                    <Stack
                      direction='column'
                      spacing='8px'
                      sx={{ alignItems: 'flex-end', display: 'flex' }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          color: '#5C5C5C',
                        }}
                      >
                        {book.authors}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          color: '#5C5C5C',
                        }}
                      >
                        {book.issueYear}
                      </Typography>
                    </Stack>
                  </Box>
                ))}
              </RequestHandler>
            </Box>
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                mt: '36px',
              }}
            >
              <DashButton
                buttonText={'Отмена'}
                variant={'outlined'}
                onClick={() => {
                  handleCancelClick(), handleClose();
                }}
                wrapperProps={{ maxWidth: '82px' }}
              />
              <DashButton
                buttonText={'Сохранить'}
                variant={'contained'}
                onClick={() => {
                  handleClose(), handleSaveClick();
                }}
                wrapperProps={{ maxWidth: '102px' }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

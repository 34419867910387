import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import * as React from 'react';

import DeleteIcon from '../../../assets/icons/DeleteIcon';
import HideIcon from '../../../assets/icons/HideIcon';
import MoreVertical from '../../../assets/icons/MoreVertical';
import VerifyIcon from '../../../assets/icons/VerifyIcon';
import { GET_SELECTION } from '../../../graphql/queries';
import { useAlert, useToggleSelectionVisibilityMutation } from '../../../hooks';
import DeleteCollectionModal from './delete-collection-modal';

export default function CollectionsIconMenu({
  collectionData,
  isDeleteOnlyMenu,
  collectionList,
  idList,
  setIdList,
  onDelete,
  getLocation,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { showAlert } = useAlert();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [toggleSelectionMutation] = useToggleSelectionVisibilityMutation();

  const handleDeleteClick = () => {
    let result = collectionList;
    let index = collectionList.indexOf(
      collectionList.find((id) => id === collectionData),
    );
    result.splice(index, 1);
    onDelete([result].flat(1));
    idList.splice(collectionData.idx, 1), setIdList(idList);
  };

  const handlePublishClick = () => {
    toggleSelectionMutation({
      variables: {
        id: parseInt(collectionData.id, 10),
        showingStatus: 'SHOWED',
      },
      refetchQueries: [GET_SELECTION],
      onError: () =>
        showAlert({
          message: 'Ошибка при публикации подборки!',
          variant: 'error',
        }),
      onCompleted: () =>
        showAlert({ message: 'Подборка опубликована.', variant: 'info' }),
    });
  };

  const handleUnublishCkick = () => {
    toggleSelectionMutation({
      variables: {
        id: parseInt(collectionData.id, 10),
        showingStatus: 'HIDEN',
      },
      refetchQueries: [GET_SELECTION],
      onError: () =>
        showAlert({
          message: 'Ошибка при скрытии подборки!',
          variant: 'error',
        }),
      onCompleted: () =>
        showAlert({ message: 'Подборка скрыта.', variant: 'info' }),
    });
  };

  return (
    <div>
      <IconButton
        id='basic-button'
        aria-controls='basic-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            borderRadius: '4px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!isDeleteOnlyMenu ? (
          <Box>
            <MenuItem
              onClick={() => {
                handlePublishClick(), handleClose();
              }}
            >
              <VerifyIcon />
              <Typography ml='4px' sx={{ color: '#5C5C5C' }}>
                Опубликовать
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleUnublishCkick(), handleClose();
              }}
            >
              <HideIcon />
              <Typography ml='4px' sx={{ color: '#5C5C5C' }}>
                Скрыть
              </Typography>
            </MenuItem>
            <DeleteCollectionModal
              collectionData={collectionData}
              getLocation={getLocation}
              onMenuClose={handleClose}
            />
          </Box>
        ) : (
          <MenuItem
            onClick={() => {
              handleDeleteClick(), handleClose();
            }}
          >
            <DeleteIcon />
            <Typography ml='4px' sx={{ color: '#5C5C5C' }}>
              Удалить
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

import { Box, Typography } from '@mui/material';
import React from 'react';

import CountSwitcherMenu from './count-switcher-menu';
import useStyles from './styles';

const EntitiesCountSwitcher = ({
  placeholder,
  options = [10, 25, 50],
  pagination,
  wrapperProps,
  setPagination,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.countSwitcherContainer} sx={{ ...wrapperProps }}>
      <Typography sx={{ fontSize: '14px', color: '#999999' }}>
        {placeholder}
      </Typography>
      <Typography sx={{ fontSize: '14px', color: '#303030', mx: '16px' }}>
        {pagination.limit}
      </Typography>
      <Box className={classes.iconContainer}>
        <CountSwitcherMenu
          options={options}
          setPagination={setPagination}
          pagination={pagination}
        />
      </Box>
    </Box>
  );
};

export default EntitiesCountSwitcher;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  banModalContainer: {
    border: '1px solid rgba(0, 0, 0, 0.1);',
    borderRadius: '1px',
    boxShadow: 24,
    padding: '32px 24px 24px 24px',
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '664px',
    maxHeight: '300px',
    width: '100%',
    height: '100%',
  },
  changePasswordContainer: {
    border: '1px solid rgba(0, 0, 0, 0.1);',
    borderRadius: '1px',
    boxShadow: 24,
    padding: '32px 24px 24px 24px',
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    maxHeight: '250px',
    width: '100%',
    height: '100%',
  },
  autocompleteWrapper: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
    '& .MuiInput-root .MuiInput-input': {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: 0,
      color: '#303030',
      height: 'unset',
      padding: 0,
    },
    '& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #0000001A',
    },
    '& .MuiInput-root': {
      paddingRight: '0px !important',
      paddingTop: 8,
    },
    '& img': {
      width: 36,
      height: 36,
    },
  },
  autocompeteOptions: {
    '&.MuiAutocomplete-option': {
      display: 'flex',
      alignItems: 'space-between',
      justifyContent: 'space-between',
    },
  },
  addBtn: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: 0,
    color: '#303030',

    '& svg': {
      width: 20,
      height: 20,
      marginRight: 9,
    },
  },
  autocomplete: {
    '&.MuiAutocomplete-root.Mui-focused ': {
      '& .hide-add-btn': {
        display: 'none',
      },
    },
  },
}));

export default useStyles;

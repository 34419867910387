import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

import CloseIcon from '../../../assets/icons/CloseIcon';
import HideIcon from '../../../assets/icons/HideIcon';
import MoreVertical from '../../../assets/icons/MoreVertical';
import VerifyIcon from '../../../assets/icons/VerifyIcon';
import { GET_TEXTBOOKS } from '../../../graphql/queries';
import {
  usePublishTextBookMutation,
  useRejectTextBookMutation,
  useUnpublishTextBookMutation,
} from '../../../hooks';
import BooksModal from './books-modal';

export default function IconMenu({ textBookData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [publishTextBookMutation] = usePublishTextBookMutation({
    variables: {
      id: parseInt(textBookData.id, 10),
    },
    refetchQueries: [GET_TEXTBOOKS],
  });

  const [unpublishTextBookMutation] = useUnpublishTextBookMutation({
    variables: {
      id: parseInt(textBookData.id, 10),
    },
    refetchQueries: [GET_TEXTBOOKS],
  });

  const [rejectTextBookMutation] = useRejectTextBookMutation({
    variables: {
      id: parseInt(textBookData.id, 10),
    },
    refetchQueries: [GET_TEXTBOOKS],
  });

  return (
    <div>
      <IconButton
        id='basic-button'
        aria-controls='basic-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            borderRadius: '4px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            publishTextBookMutation(), handleCloseMenu;
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <VerifyIcon />
          <Typography ml='4px'>Опубликовать</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            unpublishTextBookMutation(), handleCloseMenu;
          }}
        >
          <HideIcon />
          <Typography ml='4px'>Скрыть</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            rejectTextBookMutation(), handleCloseMenu;
          }}
        >
          <CloseIcon />
          <Typography ml='4px'>Отклонить</Typography>
        </MenuItem>
        <BooksModal textBookData={textBookData} onCloseMenu={handleCloseMenu} />
      </Menu>
    </div>
  );
}

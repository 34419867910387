import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import { authLink } from './auth-link';
import { errorLink } from './error-link';
import { httpLink } from './http-link';
import { paginationMerge } from './pagination-merge';

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          ...paginationMerge('getTextbooks'),
        },
      },
    },
  }),
});

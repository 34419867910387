import { gql } from '@apollo/client';

export const GET_ALL_STATIC_PAGES = gql`
  query getAllStaticPages(
    $limit: Int
    $offset: Int
    $sort: StaticPageSort
    $searchString: String
  ) {
    getAllStaticPages(
      limit: $limit
      offset: $offset
      sort: $sort
      searchString: $searchString
    ) {
      pages {
        id
        title
        alias
        content
        publishedAt
        visible
        visibleInFooter
        metaDescription
        metaKeywords
      }
      count
    }
  }
`;

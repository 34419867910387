import { IconButton, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';

import CloseIcon from '../../../../../assets/icons/CloseIcon';
import ImageIcon from '../../../../../assets/icons/ImageIcon';
import PlusIcon from '../../../../../assets/icons/PlusIcon';
import Trash2Icon from '../../../../../assets/icons/Trash2Icon';
import DashButton from '../../../../../components/button';
import DashSwitch from '../../../../../components/switch';
import TextField from '../../../../../components/text-field';
import { IS_MD_WIDTH } from '../../../../../constants/constants';
import { GET_SELECTION_BY_ID } from '../../../../../graphql/queries';
import {
  useAddSlideMutation,
  useDeleteSlideMutation,
  useFileUpload,
} from '../../../../../hooks';
import useStyles from '../../../styles';
import { AddSelectedFiles } from './modal-add-selected-files';

export default function SliderModal({
  selectionId,
  autoScrolling,
  setAutoScrolling,
  disabled,
  slides,
}) {
  const classes = useStyles();
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    onResetFiles(), setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [imageAddingPermission, setimageAddingPermission] =
    React.useState(false);

  const initialValues = {
    altText: '',
    imageURL: '',
  };

  const {
    selectedFiles,
    onDeleteFile,
    onResetFiles,
    getRootProps,
    getInputProps,
  } = useFileUpload();

  const [addSlideMutation] = useAddSlideMutation();
  const [deleteSlideMutation] = useDeleteSlideMutation();

  const handleFormSubmit = (values, { resetForm }) => {
    if (selectedFiles.length > 0 || values.imageURL !== '') {
      addSlideMutation({
        variables: {
          selectionId: parseInt(selectionId, 10),
          slide: {
            alt: values.altText || 'sliderImage',
            url: selectedFiles.length === 0 ? values.imageURL : '',
            file: values.imageURL === '' ? selectedFiles[0] : undefined,
          },
        },
        refetchQueries: [GET_SELECTION_BY_ID],
      });
    }
    resetForm();
    onResetFiles();
  };

  const handleDeleteSlide = (id) => {
    deleteSlideMutation({
      variables: {
        id: id,
      },
      refetchQueries: [GET_SELECTION_BY_ID],
    });
  };

  const permissionControl = (value) => {
    if (value === '' && open) {
      setimageAddingPermission(false);
    } else if (value !== '' && open) {
      setimageAddingPermission(true);
    }
  };

  const renderSlidesList = () => {
    return (
      <>
        {slides?.map((slide) => (
          <Box key={slide.id}>
            <Box
              sx={{
                backgroundColor: 'gray',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '16px',
                display: 'flex',
                height: '200px',
                overflow: 'hidden',
              }}
            >
              <Box
                width='72px'
                height='72px'
                display='flex'
                justifyContent='center'
                alignItems='center'
                backgroundColor='white'
                borderRadius='12px'
                position='absolute'
                sx={{
                  cursor: 'pointer',
                  zIndex: 10,
                  '&:hover': {
                    backgroundColor: '#EEF9FD',
                  },
                }}
                onClick={() => handleDeleteSlide(slide.id)}
              >
                <Trash2Icon width='40' height='40' color='red' />
              </Box>
              <Box>
                <img
                  src={slide.url}
                  alt={slide.alt}
                  style={{ width: '720px' }}
                />
              </Box>
            </Box>
            <Stack direction='column' spacing='20px' my='20px'>
              <TextField value={slide.alt} placeholder='alt' disabled />
              <TextField value={slide.url} placeholder='URL адрес' disabled />
            </Stack>
          </Box>
        ))}
      </>
    );
  };
  return (
    <div>
      <DashButton
        buttonText={isMdWidth ? 'Изображения слайдера' : ''}
        variant={'contained'}
        startIcon={<ImageIcon stroke={'#FFF'} height={'18'} width={'18'} />}
        onClick={handleOpen}
        disabled={disabled}
        wrapperProps={{
          maxWidth: isMdWidth ? '227px' : '30px',
          '&.MuiButtonBase-root.MuiButton-root': {
            paddingLeft: !isMdWidth ? '28px' : 'none',
          },
        }}
      />
      <Modal
        keepMounted
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 250,
        }}
      >
        <Fade in={open}>
          <Box className={classes.sliderModalContainer}>
            <Box
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '28px',
                display: 'flex',
                pb: '40px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  color: '#5C5C5C',
                }}
              >
                Слайдер
              </Typography>
              <IconButton
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
              <Form>
                {renderSlidesList()}
                <div {...getRootProps()}>
                  <input
                    {...getInputProps()}
                    name='file'
                    multiple={false}
                    disabled={selectedFiles.length > 0 || imageAddingPermission}
                  />
                  <AddSelectedFiles
                    selectedFiles={selectedFiles}
                    onDeleteFile={onDeleteFile}
                  />
                </div>
                <Stack direction='column' spacing='20px' my='20px'>
                  <Field name='altText'>
                    {({ field, meta }) => (
                      <TextField
                        inputType='text'
                        placeholder='alt'
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name='imageURL'>
                    {({ field, meta }) => (
                      <TextField
                        disabled={selectedFiles.length > 0}
                        inputType='text'
                        placeholder='URL адрес'
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                        onChangeField={permissionControl(field.value)}
                      />
                    )}
                  </Field>
                </Stack>
                <Box display='flex' justifyContent='center'>
                  <DashButton
                    type='submit'
                    variant='contained'
                    buttonText='Сохранить изображение'
                    startIcon={<PlusIcon />}
                    wrapperProps={{ maxWidth: '214px' }}
                  />
                </Box>
                <Box display='flex' justifyContent='space-between' mt='48px'>
                  <DashButton
                    variant='outlined'
                    buttonText='Отмена'
                    onClick={() => {
                      handleClose();
                    }}
                    wrapperProps={{ maxWidth: '50px' }}
                  />
                  <Box display='flex' flexDirection='row' alignItems='center'>
                    <Typography fontSize='12px' color='#5C5C5C' mr='8px'>
                      Автопрокрутка изображений
                    </Typography>
                    <DashSwitch
                      onChange={setAutoScrolling}
                      checked={autoScrolling}
                    />
                    <DashButton
                      type='submit'
                      variant='contained'
                      buttonText='Сохранить'
                      onClick={() => {
                        handleClose();
                      }}
                      wrapperProps={{ maxWidth: '102px', ml: '40px' }}
                    />
                  </Box>
                </Box>
              </Form>
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import { gql } from '@apollo/client';

export const GET_STATIC_PAGE = gql`
  query getStaticPage($id: Int!) {
    getStaticPage(id: $id) {
      id
      title
      alias
      content
      publishedAt
      visible
      visibleInFooter
      metaDescription
      metaKeywords
    }
  }
`;

import React, { createContext, useState } from 'react';

const ModalContext = createContext(false);

const ModalProvider = ({ children }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const toggleFunction = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <ModalContext.Provider value={{ isOpenModal, toggleFunction }}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };

import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  query signIn(
    $email: String!
    $password: String!
    $role: Role!
    $rememberMe: Boolean
  ) {
    signIn(
      email: $email
      password: $password
      role: $role
      rememberMe: $rememberMe
    ) {
      token
      user {
        id
        email
        fullName
      }
    }
  }
`;

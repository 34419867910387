import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Group1Icon from '../../../assets/icons/Group1Icon';
import Group2Icon from '../../../assets/icons/Group2Icon';
import Group3Icon from '../../../assets/icons/Group3Icon';
import OverflowDragIcon from '../../../assets/icons/OverflowDragIcon';
import { RequestHandler } from '../../../components/request-handler/request-handler';
import {
  useChangeSelectionOrderByIdMutation,
  useGetSelectionQuery,
} from '../../../hooks';
import useStyles from '../styles';
import CollectionsIconMenu from './collections-icon-menu';

const CollectionsTablePart = ({ filter, droppableId }) => {
  const classes = useStyles();
  let items;

  // eslint-disable-next-line no-unused-vars
  const { data: collections, ...getCollectionsQueryProps } =
    useGetSelectionQuery({
      variables: {
        search: filter.searchString,
      },
      onCompleted: (collections) => {
        updateCollectionsOrder(collections.getSelection);
      },
    });

  const [changeSelectionOrderByIdMutation] =
    useChangeSelectionOrderByIdMutation();

  const [collectionsOrder, updateCollectionsOrder] = React.useState();

  const getCurrentCollectionPart = () => {
    const result = [];
    result.push(collectionsOrder?.filter((item) => item.page === droppableId));
    return result.flat(1);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    items = collectionsOrder;
    const reorderedItem = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem[0]);
    changeSelectionOrderByIdMutation({
      variables: {
        id: parseInt(reorderedItem[0].id, 10),
        placedOnPlaceId: parseInt(items[result.source.index].id, 10),
      },
    });
    updateCollectionsOrder(items);
  };

  const getLocation = (page) => {
    let location;
    switch (page) {
      case 'HOME_PAGE':
        location = 'Главная';
        break;
      case 'SEARCH_RESULTS_PAGE':
        location = 'Поиск';
        break;
      case 'NOVELTIES_PAGE':
        location = 'Новинки';
        break;
      case 'NEWS_MATERIAL_PAGE':
        location = 'Новости';
        break;
      case 'CHECKOUT_PAGE':
        location = 'Рассчёт';
        break;
      default:
        location = 'Ошибка';
    }
    return location;
  };

  return (
    <>
      <RequestHandler {...getCollectionsQueryProps}>
        <TableContainer>
          <Table padding='none'>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId={droppableId}>
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{ mb: '100px' }}
                  >
                    {getCurrentCollectionPart()?.map((collection, idx) => (
                      <Draggable
                        key={idx}
                        draggableId={idx.toString()}
                        index={idx}
                      >
                        {(provided) => (
                          <TableRow
                            className={classes.collectionsTableRow}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <TableCell
                              align='left'
                              sx={{
                                minWidth: '250px',
                                width: '1034px',
                                fontSize: '15px',
                                textDecoration: 'underline',
                                letterSpacing: '0.03em',
                                color: '#0B486B',
                              }}
                            >
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Box>
                                  <OverflowDragIcon />
                                </Box>
                                <Typography sx={{ pl: '16px' }}>
                                  {collection?.title}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              align='left'
                              sx={{
                                minWidth: '120px',
                                width: '120px',
                                height: '56px',
                                fontSize: '12px',
                                color: '#303030',
                              }}
                            >
                              {getLocation(collection?.page)}
                            </TableCell>
                            <TableCell
                              align='center'
                              sx={{
                                minWidth: '100px',
                                width: '200px',
                              }}
                            >
                              {collection?.style === 'HORIZONTAL' ? (
                                <Group3Icon />
                              ) : collection?.style === 'MAIN_LEFT' ? (
                                <Group1Icon />
                              ) : (
                                <Group2Icon />
                              )}
                            </TableCell>
                            <TableCell
                              align='center'
                              sx={{
                                minWidth: '120px',
                                width: '170px',
                                fontSize: '12px',
                                color: '#303030',
                              }}
                            >
                              {collection?.style === 'HORIZONTAL'
                                ? null
                                : collection?.slider.length}
                            </TableCell>
                            <TableCell
                              align='right'
                              sx={{
                                minWidth: '100px',
                                width: '174px',
                                letterSpacing: '0.03em',
                                color:
                                  collection?.showingStatus === 'SHOWED'
                                    ? '#0BB126'
                                    : '#999999',
                                fontSize: '15px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {collection?.showingStatus === 'SHOWED'
                                  ? 'Опубликована'
                                  : 'Скрыта'}
                                <CollectionsIconMenu
                                  collectionData={collection}
                                  getLocation={getLocation}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </RequestHandler>
      <Box
        sx={{
          width: '99%',
          display: 'flex',
          alignSelf: 'center',
          borderRadius: '3px',
          height: '5px',
          my: '15px',
          backgroundColor: '#0B486B',
        }}
      />
    </>
  );
};

export default CollectionsTablePart;

import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { PRIVATE_URLS } from '../../constants';
import { useAuth } from '../../hooks';

const ProtectedRoute = (Page) => {
  return function Component(...props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuth } = useAuth();

    React.useEffect(() => {
      if (
        !isAuth &&
        !localStorage.getItem('token_admin') &&
        !sessionStorage.getItem('token_admin') &&
        PRIVATE_URLS.includes(location.pathname)
      ) {
        navigate('/');
      }
      if (
        !isAuth &&
        !localStorage.getItem('token_admin') &&
        !sessionStorage.getItem('token_admin') &&
        !location.pathname.split('/').includes('users')
      ) {
        navigate('/');
      }
      if (
        !isAuth &&
        !localStorage.getItem('token_admin') &&
        !sessionStorage.getItem('token_admin') &&
        !location.pathname.split('/').includes('pages')
      ) {
        navigate('/');
      }
      if (
        isAuth &&
        localStorage.getItem('token_admin') &&
        location.pathname == '/'
      ) {
        navigate('/catalog/books');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);
    return <Page {...props} />;
  };
};

export default ProtectedRoute;

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import DashButton from '../../../components/button';
import ProtectedRoute from '../../../components/protected-route';
import { GET_ALL_USERS } from '../../../graphql/queries';
import { useAlert, useCreateNewUserMutation } from '../../../hooks';
import { createUserSchema } from '../../../validation/schemas/create-user.schema';
import useStyles from './style';

const CreateUser = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const classes = useStyles();

  const initialValues = {
    name: '',
    email: '',
    role: 'ADMIN',
    phone: '',
    password: '',
  };

  const [createNewUserMutation] = useCreateNewUserMutation();

  const handleFormSubmit = (values) => {
    if (values.role === '') {
      showAlert({
        message: 'Выберите роль',
        variant: 'error',
      });
    } else if (values.role !== '') {
      createNewUserMutation({
        variables: {
          data: {
            fullName: values.name,
            email: values.email,
            phone: values.phone.toString(),
            password: values.password,
            role: values.role,
          },
        },
        onCompleted: () => {
          showAlert({
            message: 'Пользователь успешно создан.',
            variant: 'success',
          }),
            navigate(-1);
        },
        onError: (response) => {
          showAlert({
            message:
              response.message === 'Validation error'
                ? 'Пользователь с такой почтой уже существует!'
                : 'Ошибка при создании пользователя!',
            variant: 'error',
          });
        },
        refetchQueries: [GET_ALL_USERS],
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          margin: '32px  0px 32px 0px',
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={createUserSchema}
        >
          {({ values, handleChange }) => (
            <Form>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Stack direction='row' spacing='16px' alignItems='center'>
                  <ChevronLeftIcon onClick={() => navigate(-1)} />
                  <Typography
                    sx={{
                      fontSize: '24px',
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                    }}
                  >
                    Добавить пользователя
                  </Typography>
                </Stack>
                <DashButton
                  type='submit'
                  buttonText={'Сохранить'}
                  variant='contained'
                  startIcon={<SaveIcon />}
                  wrapperProps={{ maxWidth: '128px' }}
                />
              </Box>
              <Box mt='60px' maxWidth='435px'>
                <Typography>Имя Фамилия</Typography>
                <Field name='name'>
                  {({ field, meta }) => (
                    <TextField
                      error={meta.error !== undefined}
                      helperText={meta.error}
                      variant='standard'
                      fullWidth
                      {...field}
                    />
                  )}
                </Field>
              </Box>
              <Box mt='16px' maxWidth='435px'>
                <Typography>Email</Typography>
                <Field name='email'>
                  {({ field, meta }) => (
                    <TextField
                      error={meta.error !== undefined}
                      helperText={meta.error}
                      type='email'
                      variant='standard'
                      fullWidth
                      autoComplete='off'
                      {...field}
                    />
                  )}
                </Field>
              </Box>
              <Box mt='16px' maxWidth='435px'>
                <Typography>Телефон</Typography>
                <Field name='phone'>
                  {({ field, meta }) => (
                    <TextField
                      error={meta.error !== undefined}
                      helperText={meta.error}
                      variant='standard'
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      type='number'
                      {...field}
                    />
                  )}
                </Field>
              </Box>
              <Box mt='56px' maxWidth='435px'>
                <Typography>Пароль</Typography>
                <Field name='password'>
                  {({ field, meta }) => (
                    <TextField
                      error={meta.error !== undefined}
                      helperText={meta.error}
                      variant='standard'
                      autoComplete='off'
                      type='password'
                      fullWidth
                      {...field}
                    />
                  )}
                </Field>
              </Box>
              <Field
                className={classes.dropdown}
                name='role'
                value={values.role}
                as='select'
                onChange={handleChange}
                placeholder='Выбирите роль'
              >
                <option value='ADMIN'>Администратор</option>
                <option value='AUTHOR'>Автор</option>
                <option value='USER'>Пользователь</option>
              </Field>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ProtectedRoute(CreateUser);

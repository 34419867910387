import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  article: {
    width: '100%',
    minWidth: '320px',
    height: '100%',
  },
  navigationWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '34px',
  },
  chevronLeftIcon: {
    width: '24px',
    height: '24px',
    color: '#0B486B',
    cursor: 'pointer',
    marginRight: '8px',
  },
  formTitle: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '24px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: '32px',
    marginTop: '34px',
  },
  navigationButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '916px',
    width: 520,
    minWidth: '320px',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 380,
  },
  switcherTitle: {
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    color: '#5C5C5C',
    marginRight: '8px',
    width: '150px',
  },
  saveBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
    width: '128px',
    cursor: 'pointer',
    background: '#0B486B',
    border: 'none',
    color: '#FFF',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    '&:hover': {
      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
    },
  },
  inputsWrapper: {
    display: 'flex',
    maxWidth: '916px',
    width: '100%',
    justifyContent: 'space-between',
  },
  formInput: {
    display: 'inline-block',
    maxWidth: '894px',
    width: '100%',
    minWidth: '320px',
    height: '32px',
    borderRadius: '8px',
    padding: '4px 10px 4px 10px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    color: '#5C5C5C',
    border: '1px solid #C5D7E0',
    outline: 'none',
    marginBottom: '24px',
  },
  formInputScissor: {
    display: 'inline-block',
    maxWidth: '434px',
    width: '100%',
    height: '32px',
    borderRadius: '8px',
    padding: '4px 10px 4px 10px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    color: '#5C5C5C',
    border: '1px solid #C5D7E0',
    outline: 'none',
    marginBottom: '24px',
  },
  formInputError: {
    maxWidth: '894px',
    width: '100%',
    minWidth: '320px',
    height: '32px',
    borderRadius: '8px',
    padding: '4px 10px 4px 10px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    color: '#5C5C5C',
    border: '1px solid #f5190a',
    outline: 'none',
    marginBottom: '24px',
  },
  textInputError: {
    color: '#f5190a',
    fontFamily: 'Roboto',
    fontSize: '12px',
  },
  editNewsImg: {
    maxWidth: 300,
    width: '100%',
    display: 'block',
    margin: '0px auto 20px auto',
    objectFit: 'cover',
    objectPosition: 'center',
  },
}));

export default useStyles;

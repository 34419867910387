import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
  query {
    currentUser {
      id
      email
      fullName
      phone
      dateOfBirth
      universityId
      university {
        id
        name
        icon
      }
      registrationDate
      purchasedBooksAmount
      activityStatus
      banReason
    }
  }
`;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  appBar: {
    maxWidth: '100%',
    maxHeight: '60px',
    '&.MuiAppBar-root': {
      backgroundColor: ' #0B486B',
      boxShadow: 'none',
      zIndex: 1,
    },
  },
  toolbarRoot: {
    '&.MuiToolbar-root': {
      minHeight: 56,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
      margin: '0',
    },
  },
  headerWrapper: {
    width: '100%',
    minWidth: '100vw',
  },
  logoImg: {
    width: '230px',
    height: '40px',
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuContainer: {
    marginLeft: '20px',
    textDecoration: 'none',
  },
  menuText: {
    fontFamily: 'Roboto',
    color: '#ffffff',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0em',
  },
  headerLine: {
    backgroundColor: '#FFFFFF',
    width: '1px',
    height: '54px',
    marginLeft: '40px',
  },
}));

export default useStyles;

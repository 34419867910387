import React from 'react';

function OverflowDragIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#C5D7E0'
        d='M8 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM16 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM16 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8 19.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM16 19.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z'
      ></path>
    </svg>
  );
}

export default OverflowDragIcon;

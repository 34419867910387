import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    '&.MuiButtonBase-root.MuiButton-root': {
      height: 32,
      width: '100%',
      borderRadius: 8,
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 'normal',
      textTransform: 'initial',
      padding: '8px 16px',
      whiteSpace: 'nowrap',
    },
    '&.MuiButton-containedPrimary': {
      background: '#0B486B',
      border: '1px solid #0B486B',
      '&:hover': {
        background: '#0B486B',
      },
    },
    '&.MuiButton-string': {
      background: '#F2453D;',
      color: 'white',
      border: '1px',
      '&:hover': {
        background: 'red',
      },
    },
    '&.MuiButton-outlined': {
      border: '1px solid #0B486B',
      color: '#0B486B',
      '&:hover': {
        borderColor: '#0B486B',
      },
    },
    '&.Mui-disabled ': {
      color: '#fff',
      opacity: 0.8,
    },
    '& *:hover': {
      cursor: 'pointer',
    },
  },
  buttonText: {
    '&.MuiTypography-root': {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 'normal',
      textTransform: 'initial',
    },
  },
}));

export default useStyles;

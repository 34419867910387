import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DashButton from '../../../components/button';
import RequestHandler from '../../../components/request-handler/request-handler';
import DashSwitch from '../../../components/switch';
import TextField from '../../../components/text-field';
import {
  useAlert,
  useCreateNewOrEditOldStaticPage,
  useGetStaticPage,
} from '../../../hooks';
import MyEditor from '../text-editor';

const AddStaticPage = () => {
  const [publishStatus, setPublishStatus] = React.useState(false);
  const [footerVisibility, setFooterVisibility] = React.useState(false);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();
  const [createNewOrEditOldStaticPageMutation] =
    useCreateNewOrEditOldStaticPage();

  const { data: page, ...getStaticPageQueryProps } = useGetStaticPage({
    variables: {
      id: parseInt(id, 10),
    },
    onCompleted: (response) => {
      setPublishStatus(response?.getStaticPage?.visible);
      setFooterVisibility(response?.getStaticPage?.visibleInFooter);
    },
  });

  const initialValues = {
    id: parseInt(id, 10),
    name: page?.getStaticPage.title || '',
    metaTags: page?.getStaticPage.metaKeywords || '',
    url: page?.getStaticPage.alias || '',
    editorState: page?.getStaticPage.content || '',
  };

  const handleFormSubmit = (values) => {
    if (values.editorState === '') {
      showAlert({
        message: 'Заполните обязательные поля!',
        variant: 'warning',
      });
    } else if (values.editorState !== '') {
      createNewOrEditOldStaticPageMutation({
        variables: {
          input: {
            id: values.id || null,
            title: values.name,
            alias: values.url,
            visible: publishStatus,
            visibleInFooter: footerVisibility,
            metaKeywords: values.metaTags,
            metaDescription: values.metaTags,
            content: values.editorState,
          },
        },
        onCompleted: () => {
          showAlert({
            message: 'Данные успешно сохранены',
            variant: 'success',
          });
          navigate(-1);
        },
        onError: () => {
          showAlert({ message: 'Данные не сохранены', variant: 'error' });
        },
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          margin: '32px  0px 32px 0px',
        }}
      >
        <RequestHandler {...getStaticPageQueryProps}>
          <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
            {({ setFieldValue }) => (
              <Form>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <Stack direction='row' spacing='16px' alignItems='center'>
                    <ChevronLeftIcon onClick={() => navigate(-1)} />
                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontFamily: 'Roboto',
                        fontWeight: '400',
                      }}
                    >
                      {page?.getStaticPage.title}
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing='20px' alignItems='center'>
                    <Box display='flex' flexDirection='row'>
                      <Typography width='155px' mr='8px' textAlign='right'>
                        {publishStatus ? 'Опубликовано' : 'Снято с публикации'}
                      </Typography>
                      <DashSwitch
                        onChange={setPublishStatus}
                        checked={publishStatus}
                      />
                    </Box>
                    <Box display='flex' flexDirection='row'>
                      <Typography width='202px' mr='8px' textAlign='right'>
                        {footerVisibility
                          ? 'Отображается в футере'
                          : 'Не отображается в футере'}
                      </Typography>
                      <DashSwitch
                        onChange={setFooterVisibility}
                        checked={footerVisibility}
                      />
                    </Box>
                    <DashButton
                      type='submit'
                      variant='contained'
                      buttonText={'Сохранить'}
                      startIcon={<SaveIcon />}
                      wrapperProps={{ maxWidth: '128px' }}
                    />
                  </Stack>
                </Box>
                <Box display='flex' alignItems='center' flexDirection='column'>
                  <Box mt='44px' maxWidth='893px' width='100%'>
                    <Field name='name'>
                      {({ field, meta }) => (
                        <TextField
                          error={meta.error !== undefined}
                          placeholder='Название'
                          helperText={meta.error}
                          variant='standard'
                          fullWidth
                          required
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box mt='24px' maxWidth='893px' width='100%'>
                    <Field name='metaTags'>
                      {({ field, meta }) => (
                        <TextField
                          error={meta.error !== undefined}
                          placeholder='Мета-теги'
                          helperText={meta.error}
                          variant='standard'
                          fullWidth
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box mt='24px' maxWidth='893px' width='100%'>
                    <Field name='url'>
                      {({ field, meta }) => (
                        <TextField
                          error={meta.error !== undefined}
                          placeholder='URL'
                          helperText={meta.error}
                          variant='standard'
                          fullWidth
                          required
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                  <MyEditor
                    setFieldValue={setFieldValue}
                    initialValue={page?.getStaticPage?.content || ''}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </RequestHandler>
      </Box>
    </Box>
  );
};

export default AddStaticPage;

import { Box } from '@mui/system';
import React from 'react';
import { Outlet } from 'react-router-dom';

import NavigationTab from '../../components/navigation-tab';
import ProtectedRoute from '../../components/protected-route';
import { ROUTES } from '../../constants/';

const NAVIGATION_LIST = [
  { path: ROUTES.usersList, name: 'Пользователи' },
  { path: ROUTES.adminsAuthorsList, name: 'Администраторы и авторы' },
];

const Users = () => {
  return (
    <>
      <NavigationTab options={NAVIGATION_LIST} />
      <Box sx={{ mx: '24px' }}>
        <Outlet />
      </Box>
    </>
  );
};

export default ProtectedRoute(Users);

import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 17.6 17.6'
    >
      <g
        stroke='#0B486B'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        clipPath='url(#clip0_172_3491)'
      >
        <path d='M6.6 3.327a6.08 6.08 0 011.4-.16c4.667 0 7.334 5.333 7.334 5.333-.405.757-.888 1.47-1.44 2.127m-4.48-.714a2 2 0 11-2.827-2.826m5.373 5.373A6.714 6.714 0 018 13.833C3.334 13.833.667 8.5.667 8.5A12.3 12.3 0 014.04 4.54l7.92 7.92zM.667 1.167l14.667 14.666' />
      </g>
      <defs>
        <clipPath id='clip0_172_3491'>
          <path fill='#fff' d='M0 0H16V16H0z' transform='translate(0 .5)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;

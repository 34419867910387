import { createUploadLink } from 'apollo-upload-client';
import axios from 'axios';

const { buildAxiosFetch } = require('@lifeomic/axios-fetch');

export const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL || ''}`,
  fetch: buildAxiosFetch(axios, (config, input, init) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
    onError: (e) => console.log('ERROR IN FETCH...', e),
  })),
});

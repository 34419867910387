import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'space-between',
    backgroundColor: '#FAFAFA',
    minHeight: '100vh',
  },
  main: {
    margin: '0 0',
    width: '100%',
    maxWidth: '1400px',
    overflow: 'visible',
    paddingTop: '56px',
  },
}));

export default useStyles;

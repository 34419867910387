import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      email
      fullName
      phone
      dateOfBirth
      universityId
      university {
        id
        name
        icon
      }
      registrationDate
      purchasedBooksAmount
      activityStatus
      banReason
      role {
        id
        name
      }
    }
  }
`;

import React from 'react';

function ImageIcon({ stroke, height, width }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M14.25 2.25H3.75a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V3.75a1.5 1.5 0 00-1.5-1.5z'
      ></path>
      <path
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M6.375 7.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM15.75 11.25L12 7.5l-8.25 8.25'
      ></path>
    </svg>
  );
}

export default ImageIcon;

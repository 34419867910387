import { Box } from '@mui/system';
import React from 'react';
import { Outlet } from 'react-router-dom';

import NavigationTab from '../../components/navigation-tab';
import ProtectedRoute from '../../components/protected-route';
import { ROUTES } from '../../constants/';

const NAVIGATION_LIST = [
  { path: ROUTES.newsList, name: 'Новости' },
  { path: ROUTES.staticPagesList, name: 'Статические страницы' },
];

const NewsStaticsPages = () => {
  return (
    <React.Fragment>
      <NavigationTab options={NAVIGATION_LIST} />
      <Box sx={{ mx: '24px' }}>
        <Outlet />
      </Box>
    </React.Fragment>
  );
};

export default ProtectedRoute(NewsStaticsPages);

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  homePage: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    minHeight: '100%',
  },
  authBox: {
    backgroundColor: 'white',
    maxWidth: '434px',
    maxHeight: '304px',
    padding: '32px 24px',
    border: '1px solid #E9E9E9',
  },
  welcomeText: {
    color: '#5C5C5C',
  },
  formContainer: {
    padding: '48px 0',
    maxWidth: '386px',
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;

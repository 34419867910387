import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#FFF'
        d='M3.6 6A2.4 2.4 0 016 3.6h1.2v4.2A1.8 1.8 0 009 9.6h4.8a1.8 1.8 0 001.8-1.8V3.6h.455a2.4 2.4 0 011.697.703l1.945 1.945a2.4 2.4 0 01.703 1.697V18a2.4 2.4 0 01-2.4 2.4v-6.6a1.8 1.8 0 00-1.8-1.8H7.8A1.8 1.8 0 006 13.8v6.6A2.4 2.4 0 013.6 18V6z'
      ></path>
      <path
        fill='#FFF'
        d='M14.4 3.6h-6v4.2a.6.6 0 00.6.6h4.8a.6.6 0 00.6-.6V3.6zM16.8 13.8v6.6H7.2v-6.6a.6.6 0 01.6-.6h8.4a.6.6 0 01.6.6z'
      ></path>
    </svg>
  );
}

export default Icon;

import React from 'react';

function Group2Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='20'
      fill='none'
      viewBox='0 0 40 20'
    >
      <path fill='#C4C4C4' d='M28 0H40V20H28z'></path>
      <path fill='#C4C4C4' d='M0 11H26V20H0z'></path>
      <path fill='#C4C4C4' d='M0 0H5V9H0z'></path>
      <path fill='#C4C4C4' d='M7 0H12V9H7z'></path>
      <path fill='#C4C4C4' d='M14 0H19V9H14z'></path>
      <path fill='#C4C4C4' d='M21 0H26V9H21z'></path>
    </svg>
  );
}

export default Group2Icon;

import Slide from '@material-ui/core/Slide';
import { SnackbarProvider } from 'notistack';
import React from 'react';

import { useErrorsStore } from '../graphql/client/error-link';

export const AlertProvider = ({ children }) => {
  const { hasError, error, date } = useErrorsStore((state) => state);
  const providerRef = React.useRef();

  React.useEffect(() => {
    if (hasError) {
      providerRef.current.enqueueSnackbar(
        error?.message || error[0]?.message || '',
        {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 3000,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, hasError, date]);

  const handleShowAlert = ({ message = 'title', variant = 'success' }) => {
    providerRef.current.enqueueSnackbar(message, {
      variant: variant,
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };

  return (
    <AlertContext.Provider value={{ showAlert: handleShowAlert }}>
      <SnackbarProvider
        ref={providerRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
        maxSnack={3}
      >
        {children}
      </SnackbarProvider>
    </AlertContext.Provider>
  );
};

export const AlertContext = React.createContext({
  showAlert: () => {},
});

export const useAlertContext = () => {
  const context = React.useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within a AlertProvider');
  }
  return context;
};

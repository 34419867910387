import { Container, Stack, Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/icons/Logo';
import LogoPiIcon from '../../assets/icons/LogoPiIcon';
import { IS_LG_WIDTH, ROUTES } from '../../constants';
import { useAuth } from '../../hooks';
import NavMenu from './nav-menu';
import useStyles from './styles';
import UserMenu from './user-menu';

export const Header = () => {
  const classes = useStyles();
  const isLgWidth = useMediaQuery(IS_LG_WIDTH);
  const { onLogout, isAuth } = useAuth();

  const NAVIGATION_LIST = [
    { path: ROUTES.catalog, name: 'Каталог' },
    { path: ROUTES.users, name: 'Пользователи' },
    { path: ROUTES.news, name: 'Страницы' },
  ];

  const renderUserMenu = () => {
    if (isAuth !== true) return;
    return <UserMenu onLogout={onLogout} />;
  };

  const renderNavMenu = () => {
    if (isAuth !== true) return;
    return <NavMenu navList={NAVIGATION_LIST} />;
  };

  const renderNavTabs = () => {
    if (isAuth !== true) return;
    return (
      <>
        <Box className={classes.headerLine} />
        <Stack spacing='20px' direction='row' className={classes.menuContainer}>
          {NAVIGATION_LIST.map((route) => {
            if (!route) return null;
            return (
              <Link to={`${route.path}`} key={route.path}>
                <Typography className={classes.menuText}>
                  {route.name}
                </Typography>
              </Link>
            );
          })}
        </Stack>
      </>
    );
  };

  return (
    <AppBar className={classes.appBar}>
      <Container className={classes.headerWrapper}>
        <Toolbar className={classes.toolbarRoot} disableGutters>
          {isLgWidth ? (
            <Box className={classes.leftBlock}>
              <Box className={classes.logoImg}>
                <Link to='/'>
                  <Logo />
                </Link>
              </Box>
              {renderNavTabs()}
            </Box>
          ) : (
            <>
              {renderNavMenu()}
              <Link to='/'>
                <LogoPiIcon />
              </Link>
            </>
          )}
          {renderUserMenu()}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;

import React from 'react';

function AddIcon({ fill = '#FFF' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        fill={fill}
        stroke={fill}
        d='M16.25 9c0-3.989-3.261-7.25-7.25-7.25S1.75 5.011 1.75 9 5.011 16.25 9 16.25s7.25-3.261 7.25-7.25zM1.625 9c0-4.055 3.32-7.375 7.375-7.375S16.375 4.945 16.375 9 13.055 16.375 9 16.375 1.625 13.055 1.625 9z'
      />
      <path
        fill={fill}
        d='M13.5 8.438H9.562V4.5H8.438v3.938H4.5v1.124h3.938V13.5h1.124V9.562H13.5V8.438z'
      />
    </svg>
  );
}

export default AddIcon;

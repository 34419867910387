import { gql } from '@apollo/client';

export const GET_SUBJECTS = gql`
  query getSubjects($search: String, $limit: Int, $offset: Int) {
    getSubjects(search: $search, limit: $limit, offset: $offset) {
      subjects {
        id
        name
        alias
        countAllTextbooks
        countHidenTextbooks
        countPublishedTextbooks
        countRejectedTextbooks
        countModeratedTextbooks
      }
      count
    }
  }
`;

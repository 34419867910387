import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  catalogPage: {
    backgroundColor: 'red',
  },
  catalogContainer: {
    margin: '24px  0px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
  listItemImage: {
    height: '80px',
    width: '50px',
  },
  paginationContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '48px',
    marginTop: '32px',
  },
  priceLine: {
    border: '1px solid #000000',
    width: '64px',
  },
  booksModalContainer: {
    maxHeight: '351px',
    maxWidth: '664px',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1);',
    borderRadius: '1px',
    boxShadow: 24,
    padding: '32px 24px',
    boxSizing: 'border-box',
  },
  subjectsModalContainer: {
    maxHeight: '244px',
    maxWidth: '400px',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1);',
    borderRadius: '1px',
    boxShadow: 24,
    padding: '32px 24px 24px 24px',
    boxSizing: 'border-box',
  },
  collectionsModalContainer: {
    maxHeight: '564px',
    maxWidth: '768px',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1);',
    borderRadius: '1px',
    boxShadow: 24,
    padding: '32px 24px 24px 24px',
    boxSizing: 'border-box',
  },
  deleteCollectionModalContainer: {
    maxHeight: '288px',
    maxWidth: '456px',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1);',
    borderRadius: '1px',
    boxShadow: 24,
    padding: '32px 24px 24px 24px',
    boxSizing: 'border-box',
  },
  sliderModalContainer: {
    minHeight: '552px',
    maxWidth: '768px',
    width: '100%',
    maxHeight: '90%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1);',
    borderRadius: '1px',
    boxShadow: 24,
    padding: '32px 24px 24px 24px',
    boxSizing: 'border-box',
    overflowY: 'auto',
  },
  collectionsTableRow: {
    height: '56px',
    '&.MuiTableRow-root:hover': {
      background: 'rgba(196, 196, 196, 0.1)',
    },
  },
  inputWrapper: {
    width: '100%',
  },
  inputBase: {
    '&.MuiInputBase-root.MuiInput-root': {
      minHeight: 32,
      width: '100% ',
      display: 'flex',
      justifyContent: 'space-between',
      border: '1px solid #C5D7E0',
      backgroundColor: '#ffffff',
      borderRadius: 8,
      paddingLeft: 10,
      paddingRight: 8,
    },
    '&.MuiInputBase-root.MuiInput-root.Mui-error': {
      borderColor: 'red',
    },
    '&.MuiInputBase-root.MuiInput-root:before, &.MuiInputBase-root.MuiInput-root:after, &.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before':
      {
        content: ' ',
        borderBottom: 'none',
      },
    '& .MuiInputBase-input.MuiInput-input::placeholder, & .MuiInputBase-input.MuiInput-input':
      {
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '14px',
        letterSpacing: '0.03em',
        color: '#5C5C5C',
        opacity: 1,
        padding: 0,
      },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      padding: 0,
      marginLeft: 10,
    },
  },
  error: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '14px',
    color: 'red',
    opacity: 1,
    paddingTop: 10,
  },
  inputWrapperUnderline: {
    marginBottom: 16,
  },
  inputUnderline: {
    '&.MuiInputBase-root.MuiInput-root': {
      minHeight: 32,
      maxWidth: 382,
      minWidth: 166,
      width: '100% ',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #0000001A',
      backgroundColor: '#ffffff',
      paddingBottom: 16,

      '@media (max-width:639px)': {
        maxWidth: 'unset',
      },

      '&.MuiInputBase-root.MuiInput-root.Mui-error': {
        borderColor: 'red',
      },

      '&.MuiInputBase-root.MuiInput-root:before, &.MuiInputBase-root.MuiInput-root:after, &.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before':
        {
          content: ' ',
          borderBottom: 'none',
        },

      '& .MuiInputBase-input.MuiInput-input::placeholder, & .MuiInputBase-input.MuiInput-input':
        {
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '23px',
          color: '#303030',
          opacity: 1,
          padding: 0,
        },
      '& .MuiButtonBase-root.MuiIconButton-root': {
        padding: 0,
        marginLeft: 10,
      },
    },
  },
  label: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: 0,
    color: '#00000066',
    marginBottom: 8,
  },
  tableTdContent: {
    '&.MuiTableCell-root': {
      textAlign: 'center',
      fontSize: '14px',
      color: '#5C5C5C',
      whiteSpace: 'nowrap',
      padding: '0px 10px',
    },
  },
}));

export default useStyles;

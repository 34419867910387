import { gql } from '@apollo/client';

export const TOGGLE_PAGES_VISIBILITY = gql`
  mutation togglePagesVisibility(
    $id: Int!
    $key: VisibilityKey!
    $value: Boolean!
  ) {
    togglePagesVisibility(id: $id, key: $key, value: $value) {
      status
    }
  }
`;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  dropdownAutocomplete: {
    '&.MuiAutocomplete-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B486B',
      },
    },
    '& .MuiAutocomplete-input': {
      position: 'relative',
      zIndex: 1,
    },
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root .MuiOutlinedInput-root, &.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root':
      {
        padding: '0px 8px 0px 0px',
        backgroundColor: '#ffffff',
        borderRadius: 8,
        width: '100%',
      },
    '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
      height: 32,
      padding: '0px 4px 0px 10px',
    },
    '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input, &.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input::placeholder':
      {
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '14px',
        letterSpacing: 0,
        color: '#5C5C5C',
      },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #C5D7E0',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#C5D7E0',
      },
  },
}));

export default useStyles;

import { gql } from '@apollo/client';

export const EDIT_NEWS = gql`
  mutation (
    $id: ID
    $title: String!
    $content: String!
    $imageAlt: String!
    $visible: Boolean!
    $description: String!
    $metaDescription: String!
    $metaKeywords: String
    $file: Upload
  ) {
    EditNews(
      id: $id
      title: $title
      content: $content
      imageAlt: $imageAlt
      visible: $visible
      description: $description
      metaDescription: $metaDescription
      metaKeywords: $metaKeywords
      file: $file
    )
  }
`;

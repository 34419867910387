import { gql } from '@apollo/client';

export const GET_SELECTING_LIST = gql`
  query {
    selectingsList {
      subjects {
        id
        name
        alias
      }
      years
    }
  }
`;

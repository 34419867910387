import { IconButton, MenuItem, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import React from 'react';
import { Link } from 'react-router-dom';

import MenuIcon from '../../assets/icons/MenuIcon';

export default function NavMenu({ navList }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} sx={{ paddingX: '0px' }}>
        <MenuIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {navList.map((route) => {
          if (!route) return null;
          return (
            <Link to={route.path} key={route.name}>
              <MenuItem>
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    color: '#0B486B',
                  }}
                >
                  {route.name}
                </Typography>
              </MenuItem>
            </Link>
          );
        })}
      </Menu>
    </div>
  );
}

export const ROUTES = {
  catalog: '/catalog/books',
  books: './books',
  subjects: './subjects',
  collections: './collections',
  users: '/users/users-list',
  usersList: './users-list',
  adminsAuthorsList: './admins-authors-list',
  news: '/pages/news-list',
  newsList: './news-list',
  staticPagesList: './static-pages-list',
};

export const PRIVATE_URLS = [
  '/catalog/books',
  '/catalog/subjects',
  '/catalog/collections',
  '/catalog/collections/create',
  '/catalog/collections/edit/:id',
  '/catalog',
  '/pages',
  '/users/users-list',
  '/users/admins-authors-list',
  '/users/create-user',
  '/users-list/user-info',
  '/pages/news-list',
  '/pages/static-pages-list',
  '/pages/news-list/add-news',
  '/pages/static-pages-list/add-page',
];

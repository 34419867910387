import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

import BxBxsChevronDown from '../../assets/icons/BxBxsChevronDown';
import CloseIcon from '../../assets/icons/CloseIcon';
import useStyles from './styles';

const DropdownMenu = ({
  placeholder,
  options,
  onChangeField,
  wrapperProps,
}) => {
  const classes = useStyles();

  const handleChangeInput = (e, value) => {
    if (value === null) return onChangeField({ field: undefined });
    return onChangeField({ field: value.id });
  };

  return (
    <Autocomplete
      className={classes.dropdownAutocomplete}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      clearIcon={<CloseIcon />}
      popupIcon={<BxBxsChevronDown />}
      onChange={handleChangeInput}
      sx={{ ...wrapperProps }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            placeholder,
          }}
        />
      )}
    />
  );
};

export default DropdownMenu;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  deleteCollectionModalContainer: {
    maxHeight: '230px',
    maxWidth: '456px',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1);',
    borderRadius: '1px',
    boxShadow: 24,
    padding: '32px 24px 24px 24px',
    boxSizing: 'border-box',
  },
}));

export default useStyles;

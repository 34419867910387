import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import DashButton from '../../../components/button';
import EntitiesCountSwitcher from '../../../components/entities-count-switcher';
import Dashpagination from '../../../components/pagination';
import ProtectedRoute from '../../../components/protected-route';
import { RequestHandler } from '../../../components/request-handler/request-handler';
import TextField from '../../../components/text-field';
import { IS_MD_WIDTH } from '../../../constants';
import { useGetAllStaticPages } from '../../../hooks';
import StaticPagesIconMenu from './pages-icon-menu';

const StaticPagesList = () => {
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const [sortType, setSortType] = useState('ASC');
  const [sortField, setSortField] = useState('PUBLISHED_AT');

  const [searchInput, setSearchInput] = useState({
    searchString: '',
  });

  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    page: 1,
  });

  const { data: staticPages, ...getAllStaticPagesQueryProps } =
    useGetAllStaticPages({
      variables: {
        limit: pagination.limit,
        offset: pagination.offset,
        sort: {
          field: sortField,
          flow: sortType,
        },
        searchString: searchInput.field,
      },
    });

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          margin: '32px  0px 32px 0px',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontFamily: 'Roboto',
            fontWeight: '400',
          }}
        >
          Статические страницы
        </Typography>
        <Link to='add-page'>
          <DashButton
            variant={'contained'}
            buttonText={'Добавить страницу'}
            startIcon={<NewspaperIcon />}
            wrapperProps={{ maxWidth: '231px' }}
          />
        </Link>
      </Box>
      <Box>
        <TextField
          placeholder={'Поиск'}
          isSearchBar
          onChangeField={setSearchInput}
        />
      </Box>
      <RequestHandler {...getAllStaticPagesQueryProps}>
        <TableContainer sx={{ mt: '30px' }}>
          <Table padding='none'>
            <TableHead>
              <TableRow>
                <TableCell
                  align='left'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  Название
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        margin: 'auto 0',
                        width: '104px',
                      }}
                    >
                      Дата публикации
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <ExpandLessIcon
                        onClick={() => {
                          setSortField('PUBLISHED_AT'), setSortType('DESC');
                        }}
                        sx={{
                          display: 'inline-block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                      />
                      <ExpandMoreIcon
                        onClick={() => {
                          setSortField('PUBLISHED_AT'), setSortType('ASC');
                        }}
                        sx={{
                          display: 'block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        margin: 'auto 0',
                      }}
                    >
                      URL
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    borderBottom: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        margin: 'auto 0',
                      }}
                    >
                      Видимость
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <ExpandLessIcon
                        onClick={() => {
                          setSortField('VISIBLE'), setSortType('DESC');
                        }}
                        sx={{
                          display: 'inline-block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                      />
                      <ExpandMoreIcon
                        onClick={() => {
                          setSortField('VISIBLE'), setSortType('ASC');
                        }}
                        sx={{
                          display: 'block',
                          height: '14px',
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staticPages?.getAllStaticPages?.pages?.map((page) => (
                <TableRow key={page.id}>
                  <TableCell
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                    }}
                  >
                    <Link to={`edit-page/${page.id}`}>
                      <Typography
                        sx={{
                          marginTop: '26px',
                          fontFamily: 'Roboto',
                          fontSize: '15px',
                          textDecoration: 'underline',
                          color: '#0B486B',
                          cursor: 'pointer',
                        }}
                      >
                        {page.title}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell
                    align={'center'}
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        color: '#5C5C5C',
                      }}
                    >
                      {format(parseInt(page.publishedAt, 10), 'dd/MM/yy HH:mm')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={'center'}
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        color: '#5C5C5C',
                      }}
                    >
                      {page.alias}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={'center'}
                    sx={{
                      borderBottom: '1px solid #C5D7E0',
                      height: '80px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '15px',
                          color: '#5C5C5C',
                        }}
                      >
                        {page.visible === false ? (
                          <VisibilityOffIcon
                            style={{
                              display: 'flex',
                              color: '#0B486B',
                              width: 22,
                              height: 16,
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            style={{
                              display: 'flex',
                              color: '#0B486B',
                              width: 22,
                              height: 16,
                            }}
                          />
                        )}
                      </Typography>
                      <StaticPagesIconMenu staticPageData={page} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RequestHandler>
      {!getAllStaticPagesQueryProps.loading === true ? (
        <Box sx={{ display: 'flex', width: '100%', marginTop: '48px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '55%',
            }}
          >
            <Dashpagination
              pagination={pagination}
              setPagination={setPagination}
              pageItemsCount={staticPages?.getAllStaticPages?.count}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'end',
              width: '40%',
            }}
          >
            <EntitiesCountSwitcher
              placeholder={'Статики на странице:'}
              currentCount={pagination.limit}
              pagination={pagination}
              setPagination={setPagination}
              wrapperProps={{
                maxWidth: isMdWidth ? '191px' : 'none',
              }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default ProtectedRoute(StaticPagesList);

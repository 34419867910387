import { Button, Typography } from '@mui/material';
import React from 'react';

import useStyles from './styles';

const DashButton = ({
  buttonText,
  onClick,
  variant,
  disabled,
  endIcon,
  startIcon,
  wrapperProps,
  ...buttonProps
}) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      disabled={disabled}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      sx={{ ...wrapperProps }}
      {...buttonProps}
    >
      <Typography className={classes.buttonText}>{buttonText}</Typography>
    </Button>
  );
};

export default DashButton;

import React from 'react';

function Group3Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='44'
      height='24'
      fill='none'
      viewBox='0 0 44 24'
    >
      <path fill='#C4C4C4' d='M30 2H42V22H30z'></path>
      <path fill='#C4C4C4' d='M16 2H28V22H16z'></path>
      <path fill='#C4C4C4' d='M2 2H14V22H2z'></path>
    </svg>
  );
}

export default Group3Icon;

import { setContext } from '@apollo/client/link/context';

import { getToken } from '../../utils/getToken';

export const authLink = setContext(async (_, { headers }) => {
  const cookiesToken = getToken();
  const token =
    localStorage.getItem('token_admin') ||
    sessionStorage.getItem('token_admin') ||
    cookiesToken;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

import { gql } from '@apollo/client';

export const GET_NEWS = gql`
  query getNews(
    $limit: Int!
    $offset: Int!
    $sort: NewsPageSort
    $searchString: String
  ) {
    getNews(
      limit: $limit
      offset: $offset
      sort: $sort
      searchString: $searchString
    ) {
      news {
        id
        title
        description
        content
        image
        imageAlt
        visible
        viewsCount
        createdAt
        updatedAt
        publishedAt
        metaDescription
        metaKeywords
      }
      count
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers(
    $searchString: String
    $universityId: Int
    $limit: Int
    $offset: Int
    $roles: [Role]
    $UserSortField: UserSortField
    $SortType: SortType
  ) {
    getAllUsers(
      searchString: $searchString
      universityId: $universityId
      limit: $limit
      offset: $offset
      roles: $roles
      UserSortField: $UserSortField
      SortType: $SortType
    ) {
      users {
        id
        email
        fullName
        phone
        dateOfBirth
        universityId
        university {
          id
          name
          icon
        }
        registrationDate
        purchasedBooksAmount
        activityStatus
        banReason
        role {
          id
          name
        }
      }
      count
    }
  }
`;

export function authReducer(state, action) {
  switch (action.type) {
    case actionTypes.LOGOUT: {
      return { ...state, isAuth: false };
    }
    case actionTypes.SUCCESS_AUTH: {
      return { ...state, isAuth: true };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

export const actionTypes = {
  SUCCESS_AUTH: 'SUCCESS_AUTH',
  LOGOUT: 'LOGOUT',
};

import { gql } from '@apollo/client';

export const GET_USER_BOOKS = gql`
  query getUserBooks($options: getUserBooks) {
    getUserBooks(options: $options) {
      textbooks {
        purchaseDate
        purchasePrice
        textbook {
          id
          title
          cover
          price
          dateCreate
        }
      }
      count
    }
  }
`;

import React from 'react';

function MoreVertical() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        stroke='#111'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M12 13a1 1 0 100-2 1 1 0 000 2zM12 6a1 1 0 100-2 1 1 0 000 2zM12 20a1 1 0 100-2 1 1 0 000 2z'
      />
    </svg>
  );
}

export default MoreVertical;

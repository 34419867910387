import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React from 'react';

import EntitiesCountSwitcher from '../../../components/entities-count-switcher';
import DashPagination from '../../../components/pagination';
import ProtectedRoute from '../../../components/protected-route';
import { RequestHandler } from '../../../components/request-handler/request-handler';
import TextField from '../../../components/text-field';
import { IS_MD_WIDTH } from '../../../constants';
import { useGetSubjectsQuery } from '../../../hooks';
import useStyles from '../styles';
import SubjectsModal from './subjects-modal';

const SubjectsCatalog = () => {
  const classes = useStyles();
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const [filter, setFilter] = React.useState({ searchString: '' });
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
    page: 1,
  });

  const { data: subjects, ...subjectQueryProps } = useGetSubjectsQuery({
    variables: {
      search: filter.field,
    },
  });

  return (
    <Box className={classes.catalogContainer}>
      <Box
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Typography sx={{ padding: '0px 0px 32px 0px', fontSize: '24px' }}>
          Предметы
        </Typography>
        <SubjectsModal />
      </Box>
      <TextField placeholder={'Поиск'} onChangeField={setFilter} isSearchBar />
      <RequestHandler {...subjectQueryProps}>
        <TableContainer sx={{ mt: '16px' }}>
          <Table padding='none'>
            <TableHead>
              <TableRow>
                <TableCell
                  align='left'
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    color: '#5C5C5C',
                    minWidth: '210px',
                  }}
                >
                  Название
                </TableCell>
                <TableCell className={classes.tableTdContent}>
                  Всего книг
                </TableCell>
                <TableCell className={classes.tableTdContent}>
                  Опубликовано
                </TableCell>
                <TableCell className={classes.tableTdContent}>Cкрыто</TableCell>
                <TableCell className={classes.tableTdContent}>
                  Отклоненные
                </TableCell>
                <TableCell className={classes.tableTdContent}>
                  На модерации
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subjects?.getSubjects?.subjects.map((subject, idx) => (
                <TableRow key={idx} sx={{ height: '56px' }}>
                  <TableCell>
                    <Typography
                      sx={{
                        textDecoration: 'underline',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        color: '#0B486B',
                      }}
                    >
                      {subject.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{
                      fontSize: '14px',
                      color: '#303030',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    {subject.countAllTextbooks}
                  </TableCell>
                  <TableCell className={classes.tableTdContent}>
                    {subject.countPublishedTextbooks}
                  </TableCell>
                  <TableCell className={classes.tableTdContent}>
                    {subject.countHidenTextbooks}
                  </TableCell>
                  <TableCell className={classes.tableTdContent}>
                    {subject.countRejectedTextbooks}
                  </TableCell>
                  <TableCell className={classes.tableTdContent}>
                    {subject.countModeratedTextbooks}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RequestHandler>
      {subjectQueryProps.loading === false &&
      subjectQueryProps.error === false ? (
        <Box
          className={classes.paginationContainer}
          sx={{
            flexDirection: isMdWidth ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: isMdWidth ? '33%' : '100%' }} />
          <DashPagination
            pagination={pagination}
            setPagination={setPagination}
            pageItemsCount={subjects?.getSubjects.count}
            wrapperProps={{ width: '33%' }}
          />
          <Box
            sx={{
              width: isMdWidth ? '33%' : '100%',
              display: 'flex',
              justifyContent: isMdWidth ? 'flex-end' : 'center',
            }}
          >
            <EntitiesCountSwitcher
              placeholder={'Подборок на странице:'}
              currentCount={pagination.limit}
              pagination={pagination}
              setPagination={setPagination}
              wrapperProps={{
                maxWidth: isMdWidth ? '191px' : 'none',
              }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default ProtectedRoute(SubjectsCatalog);

import React from 'react';

function Image2Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='34'
      height='28'
      fill='none'
      viewBox='0 0 34 28'
    >
      <path
        fill='#0B486B'
        d='M30.332.667H3.665A3.337 3.337 0 00.332 4v20a3.337 3.337 0 003.333 3.333h26.667A3.337 3.337 0 0033.665 24V4A3.337 3.337 0 0030.332.667zm-22.5 5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm9.166 16.666H5.332L11.999 14l2.5 3.333 5-6.666 9.166 11.666H17z'
      ></path>
    </svg>
  );
}

export default Image2Icon;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  inputWrapper: {
    width: '100%',
  },
  inputBase: {
    '&.MuiInputBase-root.MuiInput-root': {
      minHeight: 32,
      width: '100% ',
      display: 'flex',
      justifyContent: 'space-between',
      border: '1px solid #C5D7E0',
      backgroundColor: '#ffffff',
      borderRadius: 8,
      paddingLeft: 10,
      paddingRight: 8,
    },
    '&.MuiInputBase-root.MuiInput-root.Mui-error': {
      borderColor: 'red',
    },
    '&.MuiInputBase-root.MuiInput-root:before, &.MuiInputBase-root.MuiInput-root:after, &.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before':
      {
        content: ' ',
        borderBottom: 'none',
      },
    '& .MuiInputBase-input.MuiInput-input::placeholder, & .MuiInputBase-input.MuiInput-input':
      {
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '14px',
        letterSpacing: '0.03em',
        color: '#5C5C5C',
        opacity: 1,
        padding: 0,
      },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      padding: 0,
      marginLeft: 10,
    },
  },
  error: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '14px',
    color: 'red',
    opacity: 1,
    paddingTop: 10,
  },
  inputWrapperUnderline: {
    marginBottom: 16,
  },
  inputUnderline: {
    '&.MuiInputBase-root.MuiInput-root': {
      minHeight: 32,
      maxWidth: 382,
      minWidth: 166,
      width: '100% ',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #0000001A',
      backgroundColor: '#ffffff',
      paddingBottom: 16,

      '@media (max-width:639px)': {
        maxWidth: 'unset',
      },

      '&.MuiInputBase-root.MuiInput-root.Mui-error': {
        borderColor: 'red',
      },

      '&.MuiInputBase-root.MuiInput-root:before, &.MuiInputBase-root.MuiInput-root:after, &.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before':
        {
          content: ' ',
          borderBottom: 'none',
        },

      '& .MuiInputBase-input.MuiInput-input::placeholder, & .MuiInputBase-input.MuiInput-input':
        {
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '23px',
          color: '#303030',
          opacity: 1,
          padding: 0,
        },
      '& .MuiButtonBase-root.MuiIconButton-root': {
        padding: 0,
        marginLeft: 10,
      },
    },
  },
  label: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: 0,
    color: '#00000066',
    marginBottom: 8,
  },
}));

export default useStyles;

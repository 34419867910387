import './RichText.css';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useFormikContext } from 'formik';
import React from 'react';

const MyEditor = ({ initialValue }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className={'wrapperTextEditor'}>
      <CKEditor
        editor={ClassicEditor}
        onChange={(event, editor) => {
          const data = editor.getData();
          setFieldValue('editorState', data);
        }}
        data={`<p>${initialValue}</p>`}
      />
    </div>
  );
};

export default MyEditor;

const invalidMessage = (value) => `Invalid ${value} format`;

export const EMAIL_MESSAGE = invalidMessage('email');
export const USERNAME_MESSAGE = invalidMessage('username');
export const PHONE_MESSAGE = invalidMessage('p');

export const REQUIRE_MESSAGE = 'Это поле обязательно';

export const INCORRECT_SYMBOL_MESSAGE = 'Использован запрещённый символ';

export const PHONE_SHORT_MESSAGE = (long) =>
  `Номер должен содержать минимум ${long} символов`;
export const PHONE_LONG_MESSAGE = (long) =>
  `Номер не должен превышать ${long} символов`;
export const PASSWORD_SHORT_MESSAGE = (long) =>
  `Пароль должен содержать минимум ${long} символов`;
export const PASSWORD_LONG_MESSAGE = (long) =>
  `Пароль не должен превышать ${long} символов`;

import { gql } from '@apollo/client';

export const SET_NEW_PASSWORD = gql`
  mutation ($passwordResetToken: String!, $password: String!) {
    setNewPassword(
      passwordResetToken: $passwordResetToken
      password: $password
    ) {
      status
    }
  }
`;

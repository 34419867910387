import { IconButton, MenuItem, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import CloseIcon from '../../../assets/icons/CloseIcon';
import DeleteIcon from '../../../assets/icons/DeleteIcon';
import Group1Icon from '../../../assets/icons/Group1Icon';
import Group2Icon from '../../../assets/icons/Group2Icon';
import Group3Icon from '../../../assets/icons/Group3Icon';
import TrashIcon from '../../../assets/icons/TrashIcon';
import DashButton from '../../../components/button';
import { GET_SELECTION } from '../../../graphql/queries';
import { useAlert, useDeleteSelectionMutation } from '../../../hooks';
import useStyles from '../styles';

export default function DeleteCollectiononModal({
  collectionData,
  getLocation,
  onMenuClose,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { showAlert } = useAlert();

  const [deleteSelectionMutation] = useDeleteSelectionMutation();

  const handleDeleteButtonClick = () => {
    deleteSelectionMutation({
      variables: {
        id: parseInt(collectionData.id, 10),
      },
      refetchQueries: [GET_SELECTION],
      onError: () =>
        showAlert({ message: 'Ошибка при удалени!', variant: 'error' }),
      onCompleted: () =>
        showAlert({ message: 'Подборка удалена.', variant: 'info' }),
    });
  };

  return (
    <div>
      <MenuItem
        onClick={() => {
          handleOpen();
        }}
      >
        <DeleteIcon />
        <Typography ml='4px' sx={{ color: '#5C5C5C' }}>
          Удалить
        </Typography>
      </MenuItem>
      <Modal
        keepMounted
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.deleteCollectionModalContainer}>
            <Box
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography
                id='transition-modal-title'
                variant='h6'
                component='h2'
                sx={{ fontSize: '24px', color: '#5C5C5C' }}
              >
                Удаление подборки
              </Typography>
              <IconButton
                onClick={() => {
                  onMenuClose(), handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                maxHeight: '139px',
                height: '100%',
                mt: '20px',
              }}
            >
              <Typography
                id='transition-modal-description'
                sx={{ fontSize: '16px', color: '#5C5C5C' }}
              >
                Вы действительно хотите удалить с сайта подборку?
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '16px',
                }}
              >
                <Stack direction='column' spacing='8px'>
                  <Typography sx={{ fontSize: '16px' }}>
                    Страница отображения:
                  </Typography>
                  <Typography sx={{ fontSize: '16px' }}>
                    Вид подборки:
                  </Typography>
                  <Typography sx={{ fontSize: '16px' }}>Название:</Typography>
                </Stack>
                <Stack direction='column' spacing='8px' ml='16px'>
                  <Typography>{getLocation(collectionData.page)}</Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: '#5C5C5C',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {collectionData.style === 'HORIZONTAL' ? (
                      <Group3Icon />
                    ) : collectionData.style === 'MAIN_LEFT' ? (
                      <Group1Icon />
                    ) : (
                      <Group2Icon />
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      letterSpacing: '0.03em',
                      textDecoration: 'underline',
                      color: '#0B486B',
                      lineHeight: '146%',
                    }}
                  >
                    {collectionData.title}
                  </Typography>
                </Stack>
              </Box>
            </Box>
            <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
              <DashButton
                buttonText={'Отмена'}
                variant={'outlined'}
                onClick={() => {
                  onMenuClose(), handleClose();
                }}
                wrapperProps={{ maxWidth: '139px' }}
              />
              <DashButton
                buttonText={'Удалить'}
                variant={'string'}
                startIcon={<TrashIcon />}
                onClick={() => {
                  handleDeleteButtonClick(), onMenuClose(), handleClose();
                }}
                wrapperProps={{ maxWidth: '113px' }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import { gql } from '@apollo/client';

export const GET_SELECTION_BY_ID = gql`
  query getSelectionById($id: ID!) {
    getSelectionById(id: $id) {
      id
      title
      style
      page
      mainTextbookId
      showingStatus
      sliderAutoScroling
      order
      slider {
        id
        alt
        url
      }
      textbooks {
        id
        title
        cover
        authors
        issueYear
      }
    }
  }
`;

import { IconButton } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';

import AddIcon from '../../../assets/icons/AddIcon';
import CloseIcon from '../../../assets/icons/CloseIcon';
import DashButton from '../../../components/button';
import TextField from '../../../components/text-field';
import { IS_MD_WIDTH } from '../../../constants/constants';
import useStyles from '../styles';

export default function SubjectsModal() {
  const classes = useStyles();
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <DashButton
        buttonText={isMdWidth ? 'Добавить предмет' : ''}
        variant={'contained'}
        startIcon={<AddIcon />}
        onClick={handleOpen}
        wrapperProps={{
          maxWidth: isMdWidth ? '197px' : '30px',
          '&.MuiButtonBase-root.MuiButton-root': {
            paddingLeft: !isMdWidth ? '28px' : 'none',
          },
        }}
      />
      <Modal
        keepMounted
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.subjectsModalContainer}>
            <Box
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '28px',
                display: 'flex',
                pb: '48px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  color: '#5C5C5C',
                }}
              >
                Предмет
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField placeholder={'Введите название предмета'} />
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                mt: '48px',
              }}
            >
              <DashButton
                buttonText={'Отмена'}
                variant={'outlined'}
                onClick={handleClose}
                wrapperProps={{ maxWidth: '139px' }}
              />
              <DashButton
                buttonText={'Сохранить'}
                variant={'contained'}
                onClick={handleClose}
                wrapperProps={{ maxWidth: '139px' }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React from 'react';

const SearchIcon = ({ color = '#4D6E7D', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.6781 14.929C17.8884 13.2772 18.4305 11.2292 18.1959 9.19486C17.9613 7.16048 16.9673 5.2897 15.4128 3.9568C13.8583 2.6239 11.8579 1.92718 9.81187 2.00603C7.76581 2.08488 5.82496 2.93348 4.37762 4.38206C2.93028 5.83065 2.08318 7.77238 2.00581 9.81878C1.92843 11.8652 2.62649 13.8654 3.96031 15.4191C5.29413 16.9729 7.16536 17.9657 9.19963 18.1988C11.2339 18.432 13.2812 17.8884 14.9319 16.6767H14.9306C14.9681 16.7267 15.0081 16.7742 15.0531 16.8205L19.8654 21.6335C20.0998 21.868 20.4178 21.9999 20.7493 22C21.0809 22.0001 21.399 21.8685 21.6335 21.6341C21.8681 21.3997 21.9999 21.0817 22 20.7501C22.0001 20.4184 21.8685 20.1004 21.6341 19.8658L16.8218 15.0528C16.7771 15.0075 16.7291 14.9657 16.6781 14.9278V14.929ZM17.0006 10.1235C17.0006 11.0265 16.8227 11.9205 16.4773 12.7547C16.1318 13.5889 15.6254 14.3469 14.987 14.9854C14.3486 15.6239 13.5907 16.1303 12.7567 16.4759C11.9226 16.8214 11.0286 16.9992 10.1258 16.9992C9.22301 16.9992 8.32905 16.8214 7.49497 16.4759C6.66089 16.1303 5.90302 15.6239 5.26464 14.9854C4.62626 14.3469 4.11987 13.5889 3.77438 12.7547C3.4289 11.9205 3.25108 11.0265 3.25108 10.1235C3.25108 8.29997 3.97538 6.55111 5.26464 5.26166C6.55391 3.97222 8.30252 3.24781 10.1258 3.24781C11.9491 3.24781 13.6977 3.97222 14.987 5.26166C16.2763 6.55111 17.0006 8.29997 17.0006 10.1235Z'
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;

import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const editUserSchema = yup.object().shape({
  name: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  email: yup
    .string()
    .email(MESSAGES.EMAIL_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
});

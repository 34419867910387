import React from 'react';
import { useDropzone } from 'react-dropzone';

export const useFileUpload = () => {
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setSelectedFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  React.useEffect(
    () => () => {
      selectedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [selectedFiles],
  );

  const handleResetFiles = () => {
    setSelectedFiles([]);
  };

  const handleDeleteFile = (fileToDelete) => {
    setSelectedFiles((prev) => prev.filter((file) => file !== fileToDelete));
  };

  return {
    selectedFiles,
    setSelectedFiles,
    onDeleteFile: handleDeleteFile,
    onResetFiles: handleResetFiles,
    getRootProps,
    getInputProps,
  };
};

import { gql } from '@apollo/client';

export const CREATE_SELECTION = gql`
  mutation ($data: CreateSelection!) {
    createSelection(data: $data) {
      id
      title
      style
      page
      mainTextbookId
      showingStatus
      sliderAutoScroling
      order
      slider {
        id
        alt
        url
      }
      textbooks {
        id
        title
        cover
        price
      }
    }
  }
`;

import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='#FFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M2 4h12M5.333 4V2.667a1.333 1.333 0 011.334-1.334h2.667a1.333 1.333 0 011.333 1.334V4m2 0v9.333a1.333 1.333 0 01-1.333 1.334H4.667a1.333 1.333 0 01-1.333-1.334V4h9.333zM6.667 7.333v4M9.334 7.333v4'
      />
    </svg>
  );
}

export default Icon;

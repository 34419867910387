import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <path fill='#0B486B' d='M5 10h30'></path>
      <path
        stroke='#F2453D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M5 10h30M13.333 10V6.667a3.333 3.333 0 013.334-3.334h6.666a3.333 3.333 0 013.334 3.334V10m5 0v23.333a3.333 3.333 0 01-3.334 3.334H11.667a3.333 3.333 0 01-3.334-3.334V10h23.334zM16.667 18.333v10M23.333 18.333v10'
      ></path>
    </svg>
  );
}

export default Icon;

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import React from 'react';

import FaSolidEye from '../../assets/icons/FaSolidEye';
import SearchIcon from '../../assets/icons/SearchIcon';
import * as style from './style.module.css';
import useStyles from './styles';

const TextField = ({
  inputType,
  isSearchBar,
  isBasicInput,
  placeholder,
  onChangeField,
  onChange,
  required,
  name,
  value,
  onBlur,
  touched,
  error,
  disabled,
  wrapperProps,
  multiline,
  minRows,
  rows,
}) => {
  const classes = useStyles();
  const [currentInputType, setCurrentInputType] = React.useState(inputType);

  const handleChangeType = () => {
    setCurrentInputType((prevState) =>
      prevState === 'password' ? 'text' : 'password',
    );
  };

  const handleChangeInput = (event) => {
    return onChangeField({ field: event.target.value });
  };

  return (
    <FormControl
      className={classes.inputWrapper}
      variant='standard'
      sx={{ ...wrapperProps }}
    >
      <Input
        rows={rows}
        required={required}
        multiline={multiline}
        minRows={minRows}
        placeholder={placeholder}
        type={currentInputType}
        disabled={disabled}
        name={name}
        value={value}
        onChange={isSearchBar || isBasicInput ? handleChangeInput : onChange}
        onBlur={onBlur}
        error={Boolean(touched && error)}
        endAdornment={
          inputType === 'password' ? (
            <IconButton onClick={handleChangeType}>
              <FaSolidEye />
            </IconButton>
          ) : isSearchBar ? (
            <IconButton
              className={style.searchIcon}
              disabled
              aria-label='search'
            >
              <SearchIcon />
            </IconButton>
          ) : null
        }
        className={classes.inputBase}
      />
      {touched && error && <div className={classes.error}>{error}</div>}
    </FormControl>
  );
};

export default TextField;

import React from 'react';

export const useDebounce = (value, delay = 3000) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const delayCallback = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(delayCallback);
  }, [delay, value]);

  return debouncedValue;
};

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  navLine: {
    height: '100%',
    '&:active': {
      borderBottom: '1px solid blue',
    },
  },
}));

export default useStyles;

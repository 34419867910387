import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { DeletionModal } from './deletion-modal';

const UsersIconMenu = ({ userData }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const ITEM_HEIGHT = 48;

  const handleOpenMenu = (e) => setAnchorEl(e.currentTarget);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'inline-block',
      }}
    >
      <Box>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls='long-menu'
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleOpenMenu}
        >
          <MoreVertIcon sx={{ width: '20px' }} />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
        >
          <MenuItem onClick={handleCloseMenu}>
            <Link to={`user-info/${userData.id}`}>
              <Typography color='#5C5C5C'>Редактировать</Typography>
            </Link>
          </MenuItem>
          <DeletionModal userData={userData} onCloseMenu={handleCloseMenu} />
        </Menu>
      </Box>
    </Box>
  );
};

export default UsersIconMenu;

import React from 'react';

function MenuIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#FFF'
        d='M4 6h2v2H4V6zm0 5h2v2H4v-2zm0 5h2v2H4v-2zm16-8V6H8.023v2H20zM8 11h12v2H8v-2zm0 5h12v2H8v-2z'
      ></path>
    </svg>
  );
}

export default MenuIcon;

import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 14.4 16'
    >
      <path
        fill='#0B486B'
        d='M2 4.167h-.667v8.666a1.333 1.333 0 001.334 1.334h6.666a1.333 1.333 0 001.334-1.334V4.167H2zm7.079-2L8 .833H4L2.921 2.167H0V3.5h12V2.167H9.079z'
      />
    </svg>
  );
}

export default Icon;

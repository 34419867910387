import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  countSwitcherContainer: {
    maxHeight: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    '& .MuiBox-root.Mui-focused': {
      '& svg': {
        backgroundColor: 'red',
        transform: 'rotate(180deg)',
      },
    },
    '& .MuiBox-root svg': {
      position: 'absolute',
      right: 4,
      zIndex: 0,
      transform: 'rotate(0)',
      transition: '.15s',
    },
  },
}));

export default useStyles;

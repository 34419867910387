import { Box } from '@mui/system';
import React from 'react';
import { Outlet } from 'react-router-dom';

import NavigationTab from '../../components/navigation-tab';
import ProtectedRoute from '../../components/protected-route';
import { ROUTES } from '../../constants';

const NAVIGATION_LIST = [
  { path: ROUTES.books, name: 'Книги' },
  { path: ROUTES.subjects, name: 'Предметы' },
  { path: ROUTES.collections, name: 'Подборки' },
];

const Catalog = () => {
  return (
    <>
      <NavigationTab options={NAVIGATION_LIST} />
      <Box sx={{ mx: '24px' }}>
        <Outlet />
      </Box>
    </>
  );
};

export default ProtectedRoute(Catalog);

import { gql } from '@apollo/client';

export const GET_TEXTBOOKS = gql`
  query getTextbooks($options: optionsGettingTextbooks) {
    getTextbooks(options: $options) {
      textbooks {
        id
        title
        authors
        issueYear
        subject {
          id
          name
          alias
        }
        creatorId
        subjectId
        cover
        status
        dateCreate
        price
        audioDuration
        purchasedBooksAmount
      }
      count
    }
  }
`;

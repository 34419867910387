import { useMutation } from '@apollo/client';

import * as MUTATIONS from '../graphql/mutations';

export const useSetNewPasswordMutation = (options) => {
  return useMutation(MUTATIONS.SET_NEW_PASSWORD, options);
};

export const useDeleteTextBookMutation = (options) => {
  return useMutation(MUTATIONS.DELETE_TEXTBOOK, options);
};

export const usePublishTextBookMutation = (options) => {
  return useMutation(MUTATIONS.PUBLISH_TEXTBOOK, options);
};

export const useRejectTextBookMutation = (options) => {
  return useMutation(MUTATIONS.REJECT_TEXTBOOK, options);
};

export const useUnpublishTextBookMutation = (options) => {
  return useMutation(MUTATIONS.UNPUBLISH_TEXTBOOK, options);
};

export const useChangeSelectionOrderByIdMutation = (options) => {
  return useMutation(MUTATIONS.CHANGE_SELECTION_ORDER_BY_ID, options);
};

export const useCreateSelectionMutation = (options) => {
  return useMutation(MUTATIONS.CREATE_SELECTION, options);
};

export const useEditSelectionMutation = (options) => {
  return useMutation(MUTATIONS.EDIT_SELECTION, options);
};

export const useDeleteSelectionMutation = (options) => {
  return useMutation(MUTATIONS.DELETE_SELECTION, options);
};

export const useToggleSelectionVisibilityMutation = (options) => {
  return useMutation(MUTATIONS.TOGGLE_SELECTIONS_VISIBILITY, options);
};

export const useChangePasswordMutation = (options) => {
  return useMutation(MUTATIONS.CHANGE_PASSWORD, options);
};

export const useEditAdminOrAuthor = (options) => {
  return useMutation(MUTATIONS.EDIT_ADMIN_OR_AUTHOR, options);
};

export const useDeleteUser = (options) => {
  return useMutation(MUTATIONS.DELETE_USER, options);
};

export const useDeleteNews = (options) => {
  return useMutation(MUTATIONS.DELETE_NEWS, options);
};

export const useHideNews = (options) => {
  return useMutation(MUTATIONS.HIDE_NEWS, options);
};

export const useShowNews = (options) => {
  return useMutation(MUTATIONS.SHOW_NEWS, options);
};

export const useEditNews = (options) => {
  return useMutation(MUTATIONS.EDIT_NEWS, options);
};

export const useCreatNewOrEditOldNews = (options) => {
  return useMutation(MUTATIONS.CREATE_NEW_OF_EDIT_OLD_NEWS, options);
};

export const useTogglePagesVisibility = (options) => {
  return useMutation(MUTATIONS.TOGGLE_PAGES_VISIBILITY, options);
};

export const useCreateNewOrEditOldStaticPage = (options) => {
  return useMutation(MUTATIONS.CREATE_NEW_OR_EDIT_OLD_STATIC_PAGE, options);
};

export const useDeleteStaticPage = (options) => {
  return useMutation(MUTATIONS.DELETE_STATIC_PAGE, options);
};

export const useEditUserAsAdmin = (options) => {
  return useMutation(MUTATIONS.EDIT_INFO_USER_AS_ADMIN, options);
};

export const useSendUserToBan = (options) => {
  return useMutation(MUTATIONS.SEND_USER_TO_BAN, options);
};

export const useChangePasswordAsAdmin = (options) => {
  return useMutation(MUTATIONS.CHANGE_PASSWORD_AS_ADMIN, options);
};

export const useEditAdminOrAuthorAsAdmin = (options) => {
  return useMutation(MUTATIONS.EDIT_INFO_USER_AS_ADMIN, options);
};

export const useAddSlideMutation = (options) => {
  return useMutation(MUTATIONS.ADD_SLIDE, options);
};

export const useDeleteSlideMutation = (options) => {
  return useMutation(MUTATIONS.DELETE_SLIDE, options);
};

export const useCreateNewUserMutation = (options) => {
  return useMutation(MUTATIONS.CREATE_NEW_USER, options);
};

import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

import AddIcon from '../../../assets/icons/AddIcon';
import DashButton from '../../../components/button';
import Filters from '../../../components/filters';
import ProtectedRoute from '../../../components/protected-route';
import { RequestHandler } from '../../../components/request-handler/request-handler';
import { IS_MD_WIDTH } from '../../../constants';
import { useGetSelectionQuery } from '../../../hooks';
import useStyles from '../styles';
import CollectionsTablePart from './collections-table-part';

const CollectionsCatalog = () => {
  const classes = useStyles();
  const isMdWidth = useMediaQuery(IS_MD_WIDTH);
  const [filter, setFilter] = React.useState({
    searchString: undefined,
  });

  const { ...getCollectionsQueryProps } = useGetSelectionQuery({
    variables: {
      search: filter.searchString,
    },
  });

  return (
    <Box className={classes.catalogContainer}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ padding: '0px 0px 32px 0px', fontSize: '24px' }}>
          Подборки
        </Typography>
        <Link to='create'>
          <DashButton
            buttonText={isMdWidth ? 'Добавить подборку' : ''}
            variant={'contained'}
            startIcon={<AddIcon />}
            wrapperProps={{
              maxWidth: isMdWidth ? '190px' : '30px',
              '&.MuiButtonBase-root.MuiButton-root': {
                paddingLeft: !isMdWidth ? '28px' : 'none',
              },
            }}
          />
        </Link>
      </Box>
      <Filters
        searchBarPlaceholder={'Поиск'}
        isSearchBar
        withoutFilters
        onChangeFilter={setFilter}
        filter={filter}
      />
      <RequestHandler {...getCollectionsQueryProps}>
        <TableContainer sx={{ mt: '16px', tableLayout: 'fixed' }}>
          <Table padding='none'>
            <TableHead>
              <TableRow>
                <TableCell
                  align='left'
                  sx={{
                    minWidth: '200px',
                    width: '1034px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Название
                </TableCell>
                <TableCell
                  align='left'
                  sx={{
                    minWidth: '120px',
                    width: '120px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Отображение
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    minWidth: '100px',
                    width: '200px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Вид подборки
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    minWidth: '120px',
                    width: '170px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                  }}
                >
                  Кол-во слайдов
                </TableCell>
                <TableCell
                  align='right'
                  sx={{
                    minWidth: '100px',
                    width: '174px',
                    fontSize: '12px',
                    color: '#5C5C5C',
                    pr: '32px',
                  }}
                >
                  Статус
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <CollectionsTablePart filter={filter} droppableId={'HOME_PAGE'} />
        <CollectionsTablePart
          filter={filter}
          droppableId={'SEARCH_RESULTS_PAGE'}
        />
        <CollectionsTablePart filter={filter} droppableId={'NOVELTIES_PAGE'} />
        <CollectionsTablePart
          filter={filter}
          droppableId={'NEWS_MATERIAL_PAGE'}
        />
        <CollectionsTablePart filter={filter} droppableId={'CHECKOUT_PAGE'} />
      </RequestHandler>
    </Box>
  );
};

export default ProtectedRoute(CollectionsCatalog);

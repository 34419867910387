import { onError } from '@apollo/client/link/error';
import create from 'zustand';

export const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError || graphQLErrors) {
    useErrorsStore.setState({
      hasError: false,
      error: networkError || graphQLErrors,
      date: Date.now(),
    });
  }
});

export const useErrorsStore = create(() => ({
  hasError: false,
  error: undefined,
  date: undefined,
}));

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';

import CloseIcon from '../../../assets/icons/CloseIcon';
import DashButton from '../../../components/button';
import TextField from '../../../components/text-field';
import { useAlert, useSendUserToBan } from '../../../hooks';
import useStyles from './style';

export function BanModal({ userId }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { showAlert } = useAlert();

  const [sendUserToBanMutation] = useSendUserToBan();

  const initialValues = {
    banReason: '',
  };

  const handleFormSubmit = (values, { resetForm }) => {
    sendUserToBanMutation({
      variables: {
        id: parseInt(userId, 10),
        reason: values.banReason,
      },
      onCompleted: () => {
        showAlert({
          message: 'Пользователь забанен.',
          variant: 'info',
        });
      },
      onError: () => {
        showAlert({
          message: 'Ошибка при бане пользователя!',
          variant: 'error',
        });
      },
    });
    handleClose();
    resetForm();
  };

  return (
    <div>
      <DashButton
        buttonText={'Ограничить доступ'}
        variant='contained'
        color='error'
        startIcon={<WarningAmberIcon />}
        wrapperProps={{ maxWidth: '184px' }}
        onClick={() => {
          handleOpen();
        }}
      />
      <Modal
        keepMounted
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.banModalContainer}>
            <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
              <Form>
                <Box
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                    sx={{ fontSize: '24px', color: '#5C5C5C' }}
                  >
                    Ограничить доступ
                  </Typography>
                  <IconButton type='reset' onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Field name='banReason'>
                  {({ field, meta }) => (
                    <TextField
                      multiline
                      required
                      minRows={9}
                      wrapperProps={{ mt: '40px' }}
                      placeholder={'Причина ограничения'}
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                    />
                  )}
                </Field>
                <Box display='flex' justifyContent='space-between' mt='48px'>
                  <DashButton
                    type='reset'
                    buttonText={'Отмена'}
                    variant='outlined'
                    wrapperProps={{ maxWidth: '139px' }}
                    onClick={handleClose}
                  />
                  <DashButton
                    type='submit'
                    buttonText={'Ограничить доступ'}
                    variant='contained'
                    color='error'
                    wrapperProps={{ maxWidth: '184px' }}
                    startIcon={<WarningAmberIcon />}
                  />
                </Box>
              </Form>
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

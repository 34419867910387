import { IconButton, MenuItem, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import CloseIcon from '../../../assets/icons/CloseIcon';
import DeleteIcon from '../../../assets/icons/DeleteIcon';
import TrashIcon from '../../../assets/icons/TrashIcon';
import DashButton from '../../../components/button';
import { GET_TEXTBOOKS } from '../../../graphql/queries';
import { useAlert, useDeleteTextBookMutation } from '../../../hooks';
import useStyles from '../styles';

export default function BooksModal({ textBookData, onCloseMenu }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { showAlert } = useAlert();
  const [deleteTextBookMutation] = useDeleteTextBookMutation();

  const handleDeleteButtonClick = () => {
    deleteTextBookMutation({
      variables: {
        id: parseInt(textBookData.id, 10),
      },
      refetchQueries: [GET_TEXTBOOKS],
      onError: () =>
        showAlert({ message: 'Ошибка при удалени!', variant: 'error' }),
      onCompleted: () =>
        showAlert({ message: 'Подборка удалена.', variant: 'info' }),
    });
  };

  return (
    <div>
      <MenuItem onClick={handleOpen}>
        <DeleteIcon />
        <Typography ml='4px'>Удалить</Typography>
      </MenuItem>

      <Modal
        keepMounted
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.booksModalContainer}>
            <Box
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography
                id='transition-modal-title'
                variant='h6'
                component='h2'
                sx={{ fontSize: '24px', color: '#5C5C5C' }}
              >
                Удаление книги
              </Typography>
              <IconButton
                onClick={() => {
                  onCloseMenu(), handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                maxHeight: '139px',
                height: '100%',
                mt: '30px',
                mb: '48px',
              }}
            >
              <Typography
                id='transition-modal-description'
                sx={{ fontSize: '16px', color: '#5C5C5C' }}
              >
                Вы действительно хотите удалить с сайта книгу?
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: '16px' }}>
                <img
                  src={`${process.env.REACT_APP_URL}/${textBookData?.cover}`}
                  alt={'Книга'}
                  style={{ height: '100px', width: '60px' }}
                />
                <Stack sx={{ pl: '24px' }} spacing='8px'>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      letterSpacing: '0.03em',
                      textDecoration: 'underline',
                      color: '#0B486B',
                      lineHeight: '146%',
                    }}
                  >
                    {textBookData.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#5C5C5C',
                    }}
                  >
                    {textBookData.authors}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#5C5C5C',
                    }}
                  >
                    {textBookData.issueYear}
                  </Typography>
                </Stack>
              </Box>
            </Box>
            <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
              <DashButton
                buttonText={'Отмена'}
                variant={'outlined'}
                onClick={() => {
                  onCloseMenu(), handleClose();
                }}
                wrapperProps={{ maxWidth: '139px' }}
              />
              <DashButton
                buttonText={'Удалить'}
                variant={'string'}
                startIcon={<TrashIcon />}
                onClick={() => {
                  handleDeleteButtonClick(), onCloseMenu(), handleClose();
                }}
                wrapperProps={{ maxWidth: '113px' }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import { gql } from '@apollo/client';

export const GET_SELECTION = gql`
  query getSelection($page: SelectionPages, $search: String) {
    getSelection(page: $page, search: $search) {
      id
      title
      style
      page
      mainTextbookId
      showingStatus
      sliderAutoScroling
      order
      slider {
        id
        alt
        url
      }
      textbooks {
        id
        title
        authors
        issueYear
        subject {
          id
          name
          alias
          countAllTextbooks
          countHidenTextbooks
          countPublishedTextbooks
          countRejectedTextbooks
          countModeratedTextbooks
        }
        creatorId
        subjectId
        cover
        status
        dateCreate
        price
        audioDuration
        description
        purchasedBooksAmount
      }
    }
  }
`;
